import React, { useState, useEffect } from "react";

import { API, graphqlOperation } from 'aws-amplify';

import EmailNotification from '../components/EmailNotification';

import { queryNotificationConfigs } from "../graphql/queries";

import Loading from '../components/Loading'
import PageTitle from '../components/PageTitle'

export default function Tarrifs(props) {
    //ALL OF THE NOTIFICATIONS WILL BE STORE
    const [data, setData] = useState(null);
    //AUTO UPDATE THE LIST WHEN A NEW PLUG IS ADDED ---------------
    const [newPlugAdded, setNewPlugAdded] = useState(0);

    async function getData(){
        const result = await API.graphql(
            graphqlOperation(
                queryNotificationConfigs
            )
        );            
        setData(result)
    }

    useEffect(() => {
        getData()
    },[newPlugAdded]);

    //CREATES NOTIFICATION CARDS FROM EVERY NOTIFICATION DATA ------------
    const createAllNotifications = () => {
        if (data === null){
            getData()
        }
        if (data != null){
            var notificationData = data.data.queryNotificationConfigs.notification_configs
            var myNotificationCards = []

            for(var i = 0 ; i < notificationData.length; i++){
                var notificationType1 = notificationData[i].checks_list.includes('ENERGY_EFFICIENCY') === true ? true : false
                var notificationType2 = notificationData[i].checks_list.includes('FAULT_DETECTION') === true ? true : false

                var notification = <EmailNotification user_sub={notificationData[i].user_sub} id={notificationData[i].id} addNew={false} email={notificationData[i].address} plugs={notificationData[i].serials_list} notification1={notificationType1} notification2={notificationType2} />
                myNotificationCards.push(notification)
            }
            return myNotificationCards
        }
    }
    
    return (
        <>
            {data === null ? 
                <Loading />
            :
                <div style={{alignContent:'center',  scrollBehavior:'smooth', width:'100%'}}>
                    <PageTitle title='Notifications Settings'/>

                    {createAllNotifications()}
                    <EmailNotification id='' addNew={true} email='' plugs={[]} notification1={false} notification2={false} setNewPlugAdded={setNewPlugAdded} newPlugAdded={newPlugAdded}/>
                </div>
            }
        </>
    );
}

