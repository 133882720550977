import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import Styles from './styles/applianceDailyData.module.scss';

// import Switch from "react-switch";

import { queryPowerUsed } from "../graphql/queries";

export default function Detail(props) {
    return (
        props.singlePhase === true ? 
            <div className={Styles.usageContainer}>
                <p className={Styles.usageTitle}>
                    Live Data 
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Apparent Power
                    </p>
                    <p className={Styles.infoText}>
                        {props['ApparentPower']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Current
                    </p>
                    <p className={Styles.infoText}>
                        {props['Current']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Power Factor
                    </p>
                    <p className={Styles.infoText}>
                        {props['PowerFactor']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Real Power
                    </p>
                    <p className={Styles.infoText}>
                        {props['RealPower']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Voltage
                    </p>
                    <p className={Styles.infoText}>
                        {props['Voltage']}
                    </p>
                </div>
            </div>
        :
            <div className={Styles.usageContainer}>
                <p className={Styles.usageTitle}>
                    Live Data 
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Apparent Power
                    </p>
                    <p className={Styles.infoText}>
                        L1: {props['ApparentPowerL1']}
                    </p>
                    <p className={Styles.infoText}>
                        L2: {props['ApparentPowerL2']}
                    </p>
                    <p className={Styles.infoText}>
                        L3: {props['ApparentPowerL3']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Current
                    </p>
                    <p className={Styles.infoText}>
                        L1: {props['CurrentL1']}
                    </p>
                    <p className={Styles.infoText}>
                        L2: {props['CurrentL2']}
                    </p>
                    <p className={Styles.infoText}>
                        L3: {props['CurrentL3']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Power Factor
                    </p>
                    <p className={Styles.infoText}>
                        L1: {props['PowerFactorL1']}
                    </p>
                    <p className={Styles.infoText}>
                        L2: {props['PowerFactorL2']}
                    </p>
                    <p className={Styles.infoText}>
                        L3: {props['PowerFactorL3']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Real Power
                    </p>
                    <p className={Styles.infoText}>
                        L1: {props['RealPowerL1']}
                    </p>
                    <p className={Styles.infoText}>
                        L2: {props['RealPowerL2']}
                    </p>
                    <p className={Styles.infoText}>
                        L3: {props['RealPowerL3']}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Voltage
                    </p>
                    <p className={Styles.infoText}>
                        L1: {props['VoltageL1']}
                    </p>
                    <p className={Styles.infoText}>
                        L2: {props['VoltageL2']}
                    </p>
                    <p className={Styles.infoText}>
                        L3: {props['VoltageL3']}
                    </p>
                </div>
            </div>
    )
}
