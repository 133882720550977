import React, { useState, useEffect } from "react";
import { API, graphqlOperation } from 'aws-amplify';
import Styles from './styles/applianceDailyData.module.scss';

import { queryPowerUsed, queryEfficiency } from "../graphql/queries";

export default function Detail(props) {

    // LOAD INFORMATION ABOUT THE PLUG -------------
    const [serial, setSerial] = useState(props.serial);

    //FAULT DETECTION API -=-=-=-=-=-=-=-=-=-=-=-=-=-
    const [faultData, setFaultData] = useState({});
    useEffect(() => {
        (async () => {
            await fetch('http://a72e392e4b04947db93b16da42f733f2-896573256.eu-west-2.elb.amazonaws.com/status/' + serial)
            .then(res => res.json())
            .then(
            (result) => {
                setFaultData(result)
            },
            (error) => {
                console.log(error)
            }
            )
        })();
    }, []);

    // PROD: http://ad8840cf528664e2097a89b799e2f859-680080027.eu-west-2.elb.amazonaws.com/status/
    // DEV: http://a72e392e4b04947db93b16da42f733f2-896573256.eu-west-2.elb.amazonaws.com/status/

    //EFFICIENCY API -=-=-=-=-=-=-=-=-=-=-=-=-=-
    const [error, setError] = useState(false);

    function todaysDateFormat(){
        let today = new Date()
        let todayYear = today.getFullYear()
        let todayMonth = today.getMonth()
        let todayDay = today.getDay()

        if(todayMonth < 10){
            todayMonth = '0' + todayMonth;
        }
        if(todayDay < 10){
            todayDay = '0' + todayDay;
        }

        let formattedDate = todayYear + '-' + todayMonth + '-' + todayDay

        return formattedDate
    }

    useEffect(() => {
        (async () => {
            let todaysDate = todaysDateFormat()
            try {
                const result = await API.graphql(
                    graphqlOperation(
                        queryEfficiency,
                        {
                            serial_number: props.serial,
                            carbon_multiplier: 0.21233,
                            max_date: todaysDate,
                            tariff: {tariff:{price_pence: 25, interval: {end_hour: 24, start_hour: 0}}},
                            off_peak_definitions: [
                                {name: "Power used outside peak standard working hours", threshold_pc: 20, off_peak_times: [{start_hour: 0, end_hour: 9}, {start_hour: 17.5, end_hour: 24}]}, 
                                {name: "Power used outside off-peak standard working hours", threshold_pc: 15, off_peak_times: [{start_hour: 0, end_hour: 8}, {start_hour: 18.5, end_hour: 24}]},
                                {name: "Power used outside building opening hours", threshold_pc: 15, off_peak_times: [{start_hour: 0, end_hour: 6}, {start_hour: 20, end_hour: 24}]},
                            ]
                        },
                    )
                );       
                if(result.data.queryEfficiency.data[0]['efficient'] === false || result.data.queryEfficiency.data[1]['efficient'] === false || result.data.queryEfficiency.data[2]['efficient'] === false){
                    setError(true)
                    return
                }
    
            }catch (err) {
                console.log(err)
                // setError('Oops! Something went wrong! - Building opening hours efficiency: ' + err.errors[0].message)
                return
            }
        })();
    }, []);

    {/* --------- *** DEVICE TYPES *** --------- */}
    const Devices = {
        Aircon:{
            icon: '/airCon.png',
            name: 'Air Condition',
        },
        WashingMachine:{
            icon: '/washingMachine.png',
            name: 'Washing Machine',
        },
        Boiler:{
            icon: '/boiler.png',
            name: 'Boiler',
        },
        Fan:{
            icon: '/fan.png',
            name: 'Boiler',
        },        
        CoffeeMachine:{
            icon: '/coffee-machine.png',
            name: 'Boiler',
        },        
        Refrigerator:{
            icon: '/refrigerator.png',
            name: 'Boiler',
        },
        Other:{
            icon: '/electric-appliance.png',
            name: 'Other',
        }
    }
    useEffect(() => {
        props.type === 'Air Condition' ? onDeviceIconChange(Devices.Aircon.icon) : 
        props.type === 'Washing Machine' ? onDeviceIconChange(Devices.WashingMachine.icon) : 
        props.type === 'Boiler' ? onDeviceIconChange(Devices.Boiler.icon) : 
        props.type === 'Fan' ? onDeviceIconChange(Devices.Fan.icon) : 
        props.type === 'Refrigerator' ? onDeviceIconChange(Devices.Refrigerator.icon) : 
        props.type === 'Coffee Machine' ? onDeviceIconChange(Devices.CoffeeMachine.icon) : 
        onDeviceIconChange(Devices.Other.icon)
    },[props.type]);

    // const [checked, setChecked] = useState(false);
    // const handleChange = nextChecked => {
    //     setChecked(nextChecked);
    // };

    // INITIALLY LOAD THE DATA FOR THE CURRENT DAY ---------------
    const [powerUsedToday, setPowerUsedToday] = useState(null);

    const [StartDate, setDate] = useState(new Date());
    //CALCULATE THE YESTERDAY

    const startDayCalc = (date) =>{
        var result = new Date(date);
        result.setHours(0,0,0)
        console.log('START: ' + result)
        return result
    }

    const startEndCalc = (date) =>{
        var result = new Date(date);
        result.setHours(23,59,59)
        console.log('END: ' + result)
        return result
    }

    useEffect(() => {
        (async () => {
            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'quarterhours', 
                        powerAggregationType: 'SUM',
                        powerCalculationsRange: {end_date_time: startEndCalc(StartDate), start_date_time: startDayCalc(StartDate) },
                    },
                )
            );            
            setPowerUsedToday(result.data.queryPowerUsed.preCalculations)
        })();
    }, [serial]);

    {/* --------- *** WHICH DEVICE TYPE HAS TO BE SHOWN (WHEN MORE DEVICE TYPES ARE ADDED HAVE TO CHANGE THIS AND ADD IT TO USE-EFFECT) *** --------- */}
    const [deviceIcon, onDeviceIconChange] = useState();

    return (
            <div className={Styles.usageContainer}>

                <div className={Styles.header}>
                    <img className={Styles.deviceIcon} src={deviceIcon} alt="device icon"/> 

                    <p className={Styles.serialNumber}>
                        {serial}
                    </p>

                    {/* <div className={Styles.switchView}>
                        <Switch
                            onChange={handleChange}
                            checked={checked}
                            className="react-switch"
                            onColor="#2BBCB9"
                            
                            uncheckedIcon={false}
                            checkedIcon={false}
                        />
                    </div> */}


                    {/* --------- *** DISPLAY FAULT IF THERE EXIST __ NEED TO IMPLEMENT THE FAULT DETECTED MODE __ *** --------- */}
                    {faultData !== null && faultData.is_fault === true ? 
                    <div className={Styles.faultDisplay}  style={{backgroundColor:'#FF5E15',backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0), rgba(255,255,255,0.4) )`,}}>    
                        <p style={{fontSize:16, fontWeight:'600', lineHeight:.2, color:'#FFF'}}>Faults Detected</p>
                    </div>
                    :
                    <div className={Styles.faultDisplay}  style={{backgroundColor:'#4EDB74',backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0), rgba(255,255,255,0.4) )`,}}>    
                        <p style={{fontSize:16, fontWeight:'600', lineHeight:.2}}>No Faults</p>
                    </div>
                    }

                    {/* --------- *** DISPLAY ENERGY EFFIENCY __ NEED TO IMPLEMENT THE FAULT DETECTED MODE __ *** --------- */}
                    {error === true ? 
                    <div className={Styles.faultDisplay}  style={{backgroundColor:'#FF5E15',backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0), rgba(255,255,255,0.4) )`,}}>    
                        <p style={{fontSize:16, fontWeight:'600', lineHeight:.2, color:'#FFF'}}>Not Efficient</p>
                    </div>
                    :
                    <div className={Styles.faultDisplay}  style={{backgroundColor:'#4EDB74',backgroundImage: `linear-gradient(to right, rgba(255,255,255,0.4), rgba(255,255,255,0), rgba(255,255,255,0.4) )`,}}>
                        <p style={{fontSize:16, fontWeight:'600', lineHeight:.2}}>Energy Efficient</p>
                    </div>
                    }
                </div>

                <div className={Styles.seperator} />

                <p className={Styles.usageTitle}>
                    Today's Consumption
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Total Power Used
                    </p>
                    <p className={Styles.infoText}>
                        {powerUsedToday != null && powerUsedToday.length > 0 ? powerUsedToday[0].total_kwh.toFixed(3) + ' (kWh)' : 'Loading...' }
                    </p>
                </div>

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Total Costs
                    </p>
                    <p className={Styles.infoText}>
                        {powerUsedToday != null && powerUsedToday.length > 0 ? powerUsedToday[0].total_cost.toFixed(3) + ' (GBP)' : 'Loading...' }
                    </p>
                </div>

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Total Carbon
                    </p>
                    <p className={Styles.infoText}>
                        {powerUsedToday != null && powerUsedToday.length > 0 ? powerUsedToday[0].total_carbon.toFixed(3) + ' (kg CO2e)' : 'Loading...' }
                    </p>
                </div>

            </div>
    )

}
