import React, { useState, useEffect, forwardRef } from "react";
import {LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, Brush, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { API, graphqlOperation } from 'aws-amplify';

import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Styles from './styles/history.module.scss';

import { queryPowerUsed, queryVervConnects } from "../graphql/queries";

import Loading from '../components/Loading'
import moment from 'moment'

import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();


export default function Detail(props) {
    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    //DISPLAY ALL THE PLUGS THAT THE CURRENT USER HAS
    const [allPlugs, setAllPlugs] = useState([]);
    const [selectedPlug, setSelectedPlugs] = useState([]);

    // Using useEffect to call the API once mounted and set the data
    useEffect(() => {
        (async () => {
            const result = await API.graphql({ query: queryVervConnects });
            var tempArray = []
            for(var i = 0; i < result.data.queryVervConnects.items?.length; i++ ){
                let plugInfo = {
                    value: result.data.queryVervConnects.items[i].serial,
                    label: result.data.queryVervConnects.items[i].appliance.nickname + ' (' + result.data.queryVervConnects.items[i].serial + ')'
                }
                tempArray.push(plugInfo)
            }    
            setAllPlugs(tempArray)
        })();
    }, []);
    //CUSTOM STYLE FOR THE PLUG PICKER
    const customStyles = {
        option: (provided, state) => ({
        ...provided,
        borderRadius:10,
        }),

        singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
        }
    }

    //TO KNOW WHICH CHART TO DISPLAY
    const [chartType, setChartType] = useState(1);

    //TO FIND OUT WHICH TYPE OF DATA HAS TO BE SHOWN
    const [totalPowerShow, setTotalPowerShow] = useState(true);
    const [totalCarbonShow, setTotalCarbonShow] = useState(false);
    const [totalCostShow, setTotalCostShow] = useState(false);

    //CHART DATA HOLDERS ----------
    //to know which button is selected (monthly weekly or daily)
    const [dataButtonIndex, setDataButtonIndex] = useState(2);

    //LOADING STATE
    const [isLoading, setIsLoading] = useState(true);

    //TO HOLD THE DATA FOR THE CHART
    const [data, setData] = useState(null);

    // THE DATE HOLDERS AND FUNCTIONS ----------
    //Remove 30 days from current date
    const removeDays = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() - 30);
        return result;
    }
    //add 30 days from current date
    const add60Days = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() + 60);
        return result;
    }
    //MAXIMUM OF A MONTH CAN BE PICKED
    const addDays = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() + 30);
        return result;
    }
    // //CALCULATE THE NEXT DAY
    // const addOneDay = (date) =>{
    //     var result = new Date(date);
    //     result.setDate(result.getDate() + 1);
    //     return result;
    // }
    // //CALCULATE DATE FROM WEEK NUMBER
    // function getDateOfWeek(w, y) {
    //     var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    //     return new Date(y, 0, d);
    // }
    //CALCULATE WEEK FROM A DATE
    function getWeekfromDate(date){
        //inputDate to date
        var currentdate = new Date(date);
        //calculate jan 1st
        var oneJan = new Date(currentdate.getFullYear(),0,1);

        var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
        return result
    }

    //EXPLORABLE DATES FUNCTION
    // const [exploredIndex, setExploredIndex] = useState(dataButtonIndex);
    function padTo2Digits(num) {
        return num.toString().padStart(2, '0');
    }

    async function exploreMoreButton (date) {
        var dateArrayed = date.split("/");

        const dayIs = padTo2Digits(dateArrayed[0].substring(0, 2))
        const MonthIs = padTo2Digits(dateArrayed[1].substring(0, 2))

        console.log(dateArrayed[2] + '/' + MonthIs + '/' + dayIs)

        const dateIs = dateArrayed[2] + '-' + MonthIs + '-' + dayIs

        const selectedDate = new Date( dateIs )

        console.log(selectedDate)

        setStartDate(selectedDate)
        setEndDate(addDays(selectedDate))

        switch(dataButtonIndex) {
            case 0:
                setDataButtonIndex(1)
                break;
            case 1:
                setDataButtonIndex(2)
                break;
            case 2: 
                setDataButtonIndex(3)
                break
            default:
                setDataButtonIndex(4)
        }
    }

    //DATE PICKER
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(removeDays(endDate));

    const onChange = async (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    // INITIALLY LOAD THE DATA FOR THE CHART OF THE PAST 30 DAYS ---------------
    useEffect(() => {
        getData()
    }, [startDate, endDate, dataButtonIndex, selectedPlug]);

    //FUNCTION TO LOAD DATA ON CLICK ---------------
    const getData = async () =>{
        setIsLoading(true)
        if(dataButtonIndex === 0){
            let devicesData = []
            //LOAD THE DATA
            for (const element of selectedPlug) {
                const serial = element.value
                const resultOfSecondDevice = await API.graphql(
                    graphqlOperation(
                        queryPowerUsed, 
                        {
                            serial_number: element.value,
                            powerCalculationType: 'TOTAL', 
                            intervalSize: 'months', 
                            powerCalculationsRange: {end_date_time: endDate, start_date_time: startDate },
                        },
                    )
                ).then((result)=>{
                    var tempData = result.data.queryPowerUsed.preCalculations;
                    tempData.forEach(element => {
                        element.timestamp = dateFormat(element.timestamp)
                        element.serial = serial;
                    });
                    console.log(result.data.queryPowerUsed.preCalculations)
                    devicesData.push(tempData)
                    return
                }).catch((error)=>{
                    console.log(error.errors[0].message)
                    return
                });    
            };

            devicesData.sort(function(a, b){return b.length - a.length})
            //FIND HOW MANY DATA THERE ARE
            var longestLengthAll = []
            devicesData.forEach(element=>{
                longestLengthAll.push(element.length)
            })
            var longestLength = Math.max.apply(0, longestLengthAll);

            var mergedData = []
            
            //GET DATA INTO ONE ARRAY
            for(var i=0; i < longestLength; i++ ){
                var allData = {}
                for(var j=0; j<devicesData.length; j++){
                    allData['timestamp_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['timestamp'] : 'No Data';
                    allData['total_carbon_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_carbon'] : 0;
                    allData['total_cost_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_cost'] : 0;
                    allData['total_kwh_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_kwh'] : 0;
                    allData['serial_' + j] = typeof devicesData[j][i] !== "undefined" && devicesData[j][i]['serial'];
                }
                mergedData.push(allData)
            }

            setData(mergedData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 1){
            let devicesData = []
            //LOAD THE DATA
            for (const element of selectedPlug) {
                const serial = element.value
                const resultOfSecondDevice = await API.graphql(
                    graphqlOperation(
                        queryPowerUsed,
                        {
                            serial_number: element.value,
                            powerCalculationType: 'TOTAL', 
                            intervalSize: 'weeks', 
                            powerCalculationsRange: {end_date_time: addDays(endDate), start_date_time: startDate },
                        },
                    )
                ).then((result)=>{
                    var tempData = result.data.queryPowerUsed.preCalculations;
                    tempData.forEach(element => {
                        element.timestamp = dateFormat(element.timestamp)
                        element.serial = serial;
                    });
                    devicesData.push(tempData)
                    return
                }).catch((error)=>{
                    console.log(error.errors[0].message)
                    return
                });    
            };

            devicesData.sort(function(a, b){return b.length - a.length})
            //FIND HOW MANY DATA THERE ARE
            var longestLengthAll = []
            devicesData.forEach(element=>{
                longestLengthAll.push(element.length)
            })
            var longestLength = Math.max.apply(0, longestLengthAll);

            var mergedData = []
            
            //GET DATA INTO ONE ARRAY
            for(var i=0; i < longestLength; i++ ){
                var allData = {}
                for(var j=0; j<devicesData.length; j++){
                    allData['timestamp_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['timestamp'] : 'No Data';
                    allData['total_carbon_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_carbon'] : 0;
                    allData['total_cost_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_cost'] : 0;
                    allData['total_kwh_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_kwh'] : 0;
                    allData['serial_' + j] = typeof devicesData[j][i] !== "undefined" && devicesData[j][i]['serial'];
                }
                mergedData.push(allData)
            }

            setData(mergedData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 2){
            let devicesData = []
            //LOAD THE DATA
            for (const element of selectedPlug) {
                const serial = element.value
                const resultOfSecondDevice = await API.graphql(
                    graphqlOperation(
                        queryPowerUsed,
                        {
                            serial_number: element.value,
                            powerCalculationType: 'TOTAL', 
                            intervalSize: 'days', 
                            powerCalculationsRange: {end_date_time: endDate, start_date_time: startDate },
                        },
                    )
                ).then((result)=>{
                    var tempData = result.data.queryPowerUsed.preCalculations;
                    tempData.forEach(element => {
                        element.timestamp = dateFormat(element.timestamp)
                        element.serial = serial;
                    });
                    devicesData.push(tempData)
                    return
                }).catch((error)=>{
                    console.log(error.errors[0].message)
                    return
                });    
            };

            devicesData.sort(function(a, b){return b.length - a.length})
            //FIND HOW MANY DATA THERE ARE
            var longestLengthAll = []
            devicesData.forEach(element=>{
                longestLengthAll.push(element.length)
            })
            var longestLength = Math.max.apply(0, longestLengthAll);

            var mergedData = []
            
            //GET DATA INTO ONE ARRAY
            for(var i=0; i < longestLength; i++ ){
                var allData = {}
                for(var j=0; j<devicesData.length; j++){
                    allData['timestamp_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['timestamp'] : 'No Data';
                    allData['total_carbon_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_carbon'] : 0;
                    allData['total_cost_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_cost'] : 0;
                    allData['total_kwh_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_kwh'] : 0;
                    allData['serial_' + j] = typeof devicesData[j][i] !== "undefined" && devicesData[j][i]['serial'];
                }
                mergedData.push(allData)
            }

            setData(mergedData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 3) {

            var isoStartDate = new Date(startDate.setHours(0,0,0,0)).toISOString()
            var isoEndDate = new Date(startDate.setHours(23,0,0,0)).toISOString()

            let devicesData = []
            //LOAD THE DATA
            for (const element of selectedPlug) {
                const serial = element.value
                const resultOfSecondDevice = await API.graphql(
                    graphqlOperation(
                        queryPowerUsed,
                        {
                            serial_number: element.value,
                            powerCalculationType: 'TOTAL', 
                            intervalSize: 'hours', 
                            powerCalculationsRange: {start_date_time: isoStartDate, end_date_time: isoEndDate},
                        },
                    )
                ).then((result)=>{
                    var tempData = result.data.queryPowerUsed.preCalculations;
                    tempData.forEach(element => {
                        element.timestamp = timeFormatHourly(element.timestamp)
                        element.serial = serial;
                    });
                    devicesData.push(tempData)
                    return
                }).catch((error)=>{
                    console.log(error.errors[0].message)
                    return
                });    
            };

            devicesData.sort(function(a, b){return b.length - a.length})
            //FIND HOW MANY DATA THERE ARE
            var longestLengthAll = []
            devicesData.forEach(element=>{
                longestLengthAll.push(element.length)
            })
            var longestLength = Math.max.apply(0, longestLengthAll);

            var mergedData = []
            
            //GET DATA INTO ONE ARRAY
            for(var i=0; i < longestLength; i++ ){
                var allData = {}
                for(var j=0; j<devicesData.length; j++){
                    allData['timestamp_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['timestamp'] : 'No Data';
                    allData['total_carbon_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_carbon'] : 0;
                    allData['total_cost_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_cost'] : 0;
                    allData['total_kwh_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_kwh'] : 0;
                    allData['serial_' + j] = typeof devicesData[j][i] !== "undefined" && devicesData[j][i]['serial'];
                }
                mergedData.push(allData)
            }

            setData(mergedData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 4) {

            var isoStartDate = new Date(startDate.setHours(0,0,0,0)).toISOString()
            var isoEndDate = new Date(startDate.setHours(23,0,0,0)).toISOString()

            let devicesData = []
            //LOAD THE DATA
            for (const element of selectedPlug) {
                const serial = element.value
                const resultOfSecondDevice = await API.graphql(
                    graphqlOperation(
                        queryPowerUsed,
                        {
                            serial_number: element.value,
                            powerCalculationType: 'TOTAL', 
                            intervalSize: 'quarterhours', 
                            powerCalculationsRange: {start_date_time: isoStartDate, end_date_time: isoEndDate},
                        },
                    )
                ).then((result)=>{
                    var tempData = result.data.queryPowerUsed.preCalculations;
                    tempData.forEach(element => {
                        element.timestamp = timeFormat15Min(element.timestamp)
                        element.serial = serial;
                    });
                    devicesData.push(tempData)
                    return
                }).catch((error)=>{
                    console.log(error.errors[0].message)
                    return
                });    
            };

            devicesData.sort(function(a, b){return b.length - a.length})
            //FIND HOW MANY DATA THERE ARE
            var longestLengthAll = []
            devicesData.forEach(element=>{
                longestLengthAll.push(element.length)
            })
            var longestLength = Math.max.apply(0, longestLengthAll);

            var mergedData = []
            
            //GET DATA INTO ONE ARRAY
            for(var i=0; i < longestLength; i++ ){
                var allData = {}
                for(var j=0; j<devicesData.length; j++){
                    allData['timestamp_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['timestamp'] : 'No Data';
                    allData['total_carbon_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_carbon'] : 0;
                    allData['total_cost_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_cost'] : 0;
                    allData['total_kwh_' + j] = typeof devicesData[j][i] !== "undefined" ? devicesData[j][i]['total_kwh'] : 0;
                    allData['serial_' + j] = typeof devicesData[j][i] !== "undefined" && devicesData[j][i]['serial'];
                }
                mergedData.push(allData)
            }

            setData(mergedData)
            setIsLoading(false)

            return
        }
    }
    //--------------------------------------------------------------------------

    //FUNTIONS TO HANDLE DATA HOLDER SWITCH
    const handleSwitch = (index) =>{
        setDataButtonIndex(index)
        if(index === 1){
            setEndDate(add60Days(startDate))
        }
        if(index === 2){
            setEndDate(addDays(startDate))
        }
        if(index === 3){
            setEndDate(addDays(startDate))
        }
        if(index === 4){
            setEndDate(addDays(startDate))
        }
        //load new data
    }

    //Clicking on the bars of the chart!
    const handleClick = (data, index) => {
        setActiveIndex(index)
    };
    //Which bar is active
    const [activeIndex, setActiveIndex] = useState(0);
    //Custom tooltip (WHEN HOVERING ON THE BARS)
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
        return (
            <div style={{backgroundColor:'rgba(255, 255, 255,.9)', padding:10, borderRadius:15, }}>
                <p style={{fontWeight:'bold', margin:0, padding:0, }}>{`${label}`}</p>
                <div>
                    {payload[0].dataKey === 'total_kwh_0' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh) ${payload[0].payload.serial_0}\n: `}</p>
                    :
                    payload[0].dataKey === "total_carbon_0" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e) ${payload[0].payload.serial_0}\n: `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP) ${payload[0].payload.serial_0}\n: `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0].value}\n`}</p>
                </div>
                
                {payload[1]? 
                <div>
                    {payload[1].dataKey === 'total_kwh_1' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh) ${payload[0].payload.serial_1}\n: `}</p>
                    :
                    payload[1].dataKey === "total_carbon_1" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e) ${payload[0].payload.serial_1}\n: `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP) ${payload[0].payload.serial_1}\n: `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[1].value}\n`}</p>
                </div>

                :
                <></>
                }

                {payload[2]? 
                <div>
                    {payload[2].dataKey === 'total_kwh_2' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh) ${payload[0].payload.serial_2}\n: `}</p>
                    :
                    payload[2].dataKey === "total_carbon_2" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e) ${payload[0].payload.serial_2}\n: `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP) ${payload[0].payload.serial_2}\n: `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[2].value}\n`}</p>
                </div>
                :
                <></>
                }

                {payload[3]? 
                    <div>
                        {payload[3].dataKey === 'total_kwh_3' ? 
                        <p style={{display:'inline'}}>{`Total power used (KWh) ${payload[0].payload.serial_3}\n: `}</p>
                        :
                        payload[3].dataKey === "total_carbon_3" ?
                        <p style={{display:'inline'}}>{`Total carbon (kg CO2e) ${payload[0].payload.serial_3}\n: `}</p>
                        :
                        <p style={{display:'inline'}}>{`Total cost (GBP) ${payload[0].payload.serial_3}\n: `}</p>
                        }
                        <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[3].value}\n`}</p>
                    </div>
                    :
                    <></>
                    }

                {payload[4]? 
                <div>
                    {payload[4].dataKey === 'total_kwh_4' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh) ${payload[4].payload.serial_4}\n: `}</p>
                    :
                    payload[4].dataKey === "total_carbon_4" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e) ${payload[4].payload.serial_4}\n: `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP) ${payload[4].payload.serial_0}\n: `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[4].value}\n`}</p>
                </div>
                :
                <></>
                }
            </div>
        );
        }
        return null;
    };

    function dateFormat(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        var month = parseInt(dateInit.getUTCMonth()) + 1
        var formattedDate = dateInit.getDate() + '/' + month + '/' + dateInit.getFullYear()
        return formattedDate
    }

    function timeFormatHourly(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        
        var oneHour = 3600
        var oneHourLater =  new Date(0);
        oneHourLater.setSeconds(timestamp - oneHour);

        var formattedDate = dateInit.toLocaleTimeString()
        var formattedHourLater = oneHourLater.toLocaleTimeString()

        var result = formattedHourLater + ' to ' + formattedDate

        return result
    }

    function timeFormat15Min(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        
        var oneHour = 900
        var oneHourLater =  new Date(0);
        oneHourLater.setSeconds(timestamp - oneHour);

        var formattedDate = dateInit.toLocaleTimeString()
        var formattedHourLater = oneHourLater.toLocaleTimeString()

        var result = formattedHourLater + ' to ' + formattedDate

        return result
    }

    //Function to create the graph
    function createGraph(){
        const activeItem = data[activeIndex];
        return (
            <div className='graphdiv2'>
                <div style={{ width: '100%' }}>
                    <ResponsiveContainer width="100%" height={500}>

                    {chartType === 0 ? 
                        <LineChart
                            width={150} 
                            height={350} 
                            data={data}           
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey="timestamp_0" />
                            
                            <YAxis yAxisId="left"  label={{ value: "Power", angle: -90, position: 'insideLeft', }} stroke="#34485b" />
                            <YAxis yAxisId="right" dataKey='total_carbon' orientation="right" label={{ value: 'Carbon', angle: 90, position: 'insideRight' }} stroke="#3d6955" />

                            <Tooltip content={<CustomTooltip/>} />

                            {/* <Legend wrapperStyle={{bottom:-10, marginBottom:10}} /> */}
                            
                            {totalPowerShow === true ? 
                            <>
                                {selectedPlug.length == 1 ? 
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_0' stroke="#34485b" isAnimationActive={true}/>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_1' stroke="#b85a68" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_2' stroke="#3d6955" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_3' stroke="#c45635" isAnimationActive={true}/>
                                </>
                                :
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_3' stroke="#c45635" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_kwh_4' stroke="#319e9b" isAnimationActive={true}/>
                                </>
                                }
                            </>
                            :
                            <></>
                            }
                        
                            {totalCostShow === true ? 
                            <>
                                {selectedPlug.length == 1 ? 
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_0' stroke="#34485b" isAnimationActive={true}/>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_1' stroke="#b85a68" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_2' stroke="#3d6955" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_3' stroke="#c45635" isAnimationActive={true}/>
                                </>
                                :
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_3' stroke="#c45635" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_cost_4' stroke="#319e9b" isAnimationActive={true}/>
                                </>
                                }
                            </>
                            :
                            <></>
                            }

                            {totalCarbonShow === true ? 
                            <>
                                {selectedPlug.length == 1 ? 
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_0' stroke="#34485b" isAnimationActive={true}/>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_1' stroke="#b85a68" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_2' stroke="#3d6955" isAnimationActive={true}/>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_3' stroke="#c45635" isAnimationActive={true}/>
                                </>
                                :
                                <>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_0' stroke="#34485b" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_1' stroke="#b85a68" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_2' stroke="#3d6955" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_3' stroke="#c45635" isAnimationActive={true}/>
                                    <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon_4' stroke="#319e9b" isAnimationActive={true}/>
                                </>
                                }
                            </>                            :
                            <></>
                            }
                            
                            <Brush height={20} dataKey="Date" stroke="#34485b"  />

                        </LineChart>
                        :
                        <BarChart 
                            width={150} 
                            height={350} 
                            data={data}           
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}>
                            
                            <Tooltip content={<CustomTooltip/>} />

                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey={'timestamp_0'}/>
                            
                            {totalPowerShow === true ? 
                                <>
                                {selectedPlug.length == 1 ? 
                                    <Bar isAnimationActive={true} dataKey="total_kwh_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_kwh_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_kwh_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_kwh_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                :
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_kwh_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_kwh_4" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c82f3f'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                }
                            </>
                            :
                            <></>
                            }

                            {totalCostShow === true ? 
                                <>
                                {selectedPlug.length == 1 ? 
                                    <Bar isAnimationActive={true} dataKey="total_cost_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_cost_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_cost_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_cost_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_cost_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_cost_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_cost_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                :
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_cost_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_cost_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_cost_4" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c82f3f'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                    }
                                </>
                            :
                            <></>
                            }

                            {totalCarbonShow === true ? 
                                <>
                                {selectedPlug.length == 1 ? 
                                    <Bar isAnimationActive={true} dataKey="total_carbon_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                : selectedPlug.length == 2 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_carbon_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 3 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_carbon_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                : selectedPlug.length == 4 ? 
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_carbon_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                :
                                <>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_0" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>                                        
                                    <Bar isAnimationActive={true} dataKey="total_carbon_1" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_2" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_3" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c45635'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                    <Bar isAnimationActive={true} dataKey="total_carbon_4" yAxisId="left" onClick={handleClick} >
                                        {data.map((entry, index) => (
                                            <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#c82f3f'} key={`cell-${index}`} />
                                        ))}
                                    </Bar>
                                </>
                                }
                            </>
                            :
                            <></>
                            }

                            <Brush height={20} dataKey="Date" stroke="#34485b"  />

                        </BarChart>
                        }
                    </ResponsiveContainer>

                    <div className={Styles.seperator} />
                    <>
                        {selectedPlug.length == 1 ? 
                            <div style={{backgroundColor: "#34485b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_0']} </p>
                            </div>
                        : selectedPlug.length == 2 ? 
                        <>
                            <div style={{backgroundColor: "#34485b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_0']} </p>
                            </div>
                            <div style={{backgroundColor: "#b85a68"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_1']} </p>
                            </div> 
                        </>
                        : selectedPlug.length == 3 ? 
                        <>
                            <div style={{backgroundColor: "#34485b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_0']} </p>
                            </div>
                            <div style={{backgroundColor: "#b85a68"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_1']} </p>
                            </div> 
                            <div style={{backgroundColor: "#3d6955"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_2']} </p>
                            </div> 
                        </>
                        : selectedPlug.length == 4 ? 
                        <>
                            <div style={{backgroundColor: "#34485b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_0']} </p>
                            </div>
                            <div style={{backgroundColor: "#b85a68"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_1']} </p>
                            </div> 
                            <div style={{backgroundColor: "#3d6955"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_2']} </p>
                            </div>
                            <div style={{backgroundColor: "#c45635"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_3']} </p>
                            </div> 
                        </>
                        :
                        <>
                            <div style={{backgroundColor: "#34485b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_0']} </p>
                            </div>
                            <div style={{backgroundColor: "#b85a68"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_1']} </p>
                            </div> 
                            <div style={{backgroundColor: "#3d6955"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_2']} </p>
                            </div>
                            <div style={{backgroundColor: "#c45635"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_3']} </p>
                            </div>
                            <div style={{backgroundColor: "#319e9b"}} className={Styles.deviceNameColor}>
                                <p style={{color:'white'}}>{data[0]['serial_4']} </p>
                            </div>
                        </>
                        }
                    </>  

                    <div className={Styles.seperator} />

                    <div style={{ marginTop:20, marginBottom:20, width:'100%', textAlign:'center'}}>
                        <button onClick={()=>[setTotalPowerShow(true), setTotalCostShow(false), setTotalCarbonShow(false)]} className={ totalPowerShow === false ? Styles.selectButton : Styles.selectButtonSelected} style={totalPowerShow === true ? {backgroundColor:'#319e9b', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Power
                        </button>
                        <button onClick={()=> [setTotalPowerShow(false), setTotalCostShow(true), setTotalCarbonShow(false)]} className={ totalCostShow === false ? Styles.selectButton : Styles.selectButtonSelected}  style={totalCostShow === true ? {backgroundColor:'#319e9b', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Costs
                        </button>
                        <button onClick={()=> [setTotalPowerShow(false), setTotalCostShow(false), setTotalCarbonShow(true)]} className={ totalCarbonShow === false ? Styles.selectButton : Styles.selectButtonSelected} style={totalCarbonShow === true ? {backgroundColor:'#319e9b', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Carbon
                        </button>
                    </div>
                    <div className={Styles.seperator} />

                    <div style={{ marginTop:20, marginBottom:20, width:'100%', textAlign:'center'}}>
                        {chartType === 1 && dataButtonIndex !== 4 ? 
                        <button onClick={()=>exploreMoreButton(activeItem?.timestamp_0)} className={Styles.exploreMore}>
                            Explore this date
                        </button>
                        :
                        <></>
                        }
                        <button onClick={()=> {chartType === 0 ? setChartType(1) : setChartType(0)}} className={Styles.exploreMore}>
                            {chartType === 0 ? 'Bar Chart' : 'Line Chart'}
                        </button>
                    </div>

                </div>
        </div>
        
        )
    }

    //CUSTOM INPUT BUTTON
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={Styles.goBackButton} style={{width:250}} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));
    
    return (
        <>
            <PageTitle title='Compare Devices'/>
            {/*------------------------------ HEADER ------------------------------*/}
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'CompareDevices'}/>

            <div className={[ Styles.compareDiv ]}>
                <p style={{ fontSize:'1.5em', fontWeight:'600', textAlign:'center' }}>
                    Please select the devices you would like to compare
                </p>

                <div className={Styles.seperator} />

                <div style={{ width: '100%', display:'inline-block', marginBottom:16 }}>
                    <Select
                        closeMenuOnSelect={false}
                        components={animatedComponents}
                        
                        isMulti
                        isOptionDisabled={() => selectedPlug.length >= 5}

                        options={allPlugs}

                        onChange={setSelectedPlugs}

                        styles={customStyles}

                        theme={(theme) => ({
                            ...theme,
                            borderRadius: 15,
                            colors: {
                                ...theme.colors,
                                primary25: '#BAEAE7',
                                primary50:'#B9EAE8',
                                primary: '#34485A',
                                danger:'#000',
                                dangerLight:'#FF5E15'
                                },
                        })}
                    />
                </div>
            </div>


            <div className={Styles.dataSelectorDiv}>
                {/* --------- *** THE DATA HOLDER SWITCH BUTTONS *** --------- */}
                <p style={{ fontSize:'1.5em', fontWeight:'600', }}>Please select how would you like to see your appliance data</p>

                <div className={Styles.seperator} />

                <div className={Styles.header} style={{ marginBottom:10}}>
                    <button className={[dataButtonIndex === 0 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(0)} >
                        Monthly
                    </button>

                    <button className={[dataButtonIndex === 1 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(1)}>
                        Weekly
                    </button>

                    <button className={[dataButtonIndex === 2 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(2)}>
                        Daily
                    </button>

                    <button className={[dataButtonIndex === 3 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(3)}>
                        Hourly
                    </button>

                    <button className={[dataButtonIndex === 4 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(4)}>
                        15 Minutes
                    </button>
                </div>

                <div className={Styles.seperator} />

                {/* --------- *** THE TIME PICKER *** --------- */}
                <p style={{ fontSize:'1.5em', fontWeight:'600',  }}>Please select the dates you would like to see your data</p>
                
                <div className={Styles.seperator} />

                <div className={Styles.header} >
                    {dataButtonIndex === 0 ? 

                    <DatePicker 
                    showPopperArrow={false}

                    selected={startDate}
                    onChange={onChange}

                    dateFormat="d/MM/yyyy"

                    startDate={startDate}
                    endDate={endDate}

                    selectsRange
                    showMonthYearPicker

                    calendarClassName={Styles.datePicker}
                    popperClassName={Styles.datePickerPopper}

                    customInput={<DatePickerCustomInput/>}
                    />
                    : dataButtonIndex === 1 ? 
                    <>
                        <DatePicker 
                            showPopperArrow={false}

                            selected={startDate}
                            onChange={onChange}

                            dateFormat="d/MM/yyyy"

                            startDate={startDate}
                            endDate={endDate}
                            maxDate={add60Days(startDate)}

                            selectsRange
                            showMonthYearPicker

                            calendarClassName={Styles.datePicker}
                            popperClassName={Styles.datePickerPopper}
                            showWeekNumbers
                            customInput={<DatePickerCustomInput/>}
                        />
                    </>
                    : dataButtonIndex === 2 ? 

                    <DatePicker 
                        showPopperArrow={false}
                        dateFormat="d/MM/yyyy"

                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        maxDate={addDays(startDate)}

                        calendarClassName={Styles.datePicker}
                        popperClassName={Styles.datePickerPopper}

                        customInput={<DatePickerCustomInput/>}
                    />

                    :

                    <DatePicker 
                        showPopperArrow={false}
                        dateFormat="d/MM/yyyy"

                        selected={startDate}
                        onChange={setStartDate}
                        startDate={startDate}

                        calendarClassName={Styles.datePicker}
                        popperClassName={Styles.datePickerPopper}

                        customInput={<DatePickerCustomInput/>}
                    />
                    }

                </div>

                <div className={Styles.seperator} />

                {
                isLoading === true ?
                    <div className={Styles.loader}>
                        <span></span>
                    </div> 
                :
                    data ?
                        data.length === 0 ? 
                            <p className={Styles.usageTitle} style={{marginBottom:10}}>
                                No Data Available
                            </p>
                        :
                            <>
                                {createGraph()}
                            </>
                    : 
                        <Loading/> 
                }
            </div> 
        </>
    );
}

