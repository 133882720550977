import React, { useState, useMemo, useEffect, forwardRef } from "react";
import TableWithoutButton from '../components/TableWithoutButton'
import DatePicker from "react-datepicker";
import { API, graphqlOperation } from 'aws-amplify';
import { queryUtilisation } from '../graphql/queries';
import Loading from '../components/Loading'
import Styles from './styles/info.module.scss';

import ApplianceDailyData from '../components/ApplianceDailyData'
import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'

import "react-datepicker/dist/react-datepicker.css";

export default function Detail(props) {
    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    // retrieve Utilisation data (i.e. on/off time windows) for
    // the appliance connected to the Verv Connect
    const [utilisationData, setUtilisationData] = useState(null)
    const utilisationColumns = useMemo(
        () => [
            {
                Header: "Your Connected Device's Utilisation",
                columns: [
                    {
                        Header:"On At",
                        accessor:"on_at",
                    },
                    {
                        Header:"Off At",
                        accessor:"off_at",
                    },
                    {
                        Header: "Power Used (kWh)",
                        accessor: "total_kwh",
                    },
                ]
            },
        ],
        []
    )

    useEffect(() => {
        const getUtilisationData = async (nextDate) => {
            const queryUtilisationResponse = await API.graphql(
                graphqlOperation(
                    queryUtilisation, {
                        serial_number: serial
                    }
                )
            );
            setUtilisationData(queryUtilisationResponse.data.queryUtilisation.utilisation);
        }
        getUtilisationData()
    },[]);

    /* ----------- *** DATE PICKER *** ----------- */
    const [startDate, setStartDate] = useState(new Date());
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={[Styles.goBackButton]} style={{width:250}} onClick={onClick} ref={ref}>
        {value}
        </button>
    ));

    const handleDateChange = nextDate => {
        const requestDate = new Date(nextDate);
        API.graphql(
            graphqlOperation(
                queryUtilisation, {
                    serial_number: serial,
                    year: requestDate.getFullYear(),
                    month: requestDate.getMonth()
                }
            )
        ).then(
            result => setUtilisationData(
                result.data.queryUtilisation.utilisation
            )
        )
        setStartDate(nextDate)
    };

    return (
        utilisationData ?
        <> 
            <PageTitle title='Timeline'/>
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'Timeline'}/>
            {/*------------------------------ TODAYS USAGE ------------------------------*/}
            <ApplianceDailyData serial={serial} />

            <h2 style={{ marginTop: 10, fontSize: 30, marginBottom: 0}}>Connected Appliance Utilisation Data</h2>

            <p style={{marginLeft: 50, marginRight: 50, marginBottom: 10, textAlign: 'center', fontSize: 24, fontWeight: 600,}}>
            Select the Month & Year you would like to see your connected appliances utilisation data
            </p>

            <div>
                <DatePicker
                    dateFormat="MMMM yyyy"
                    showMonthYearPicker
                    selected={startDate}
                    onChange={handleDateChange}
                    calendarClassName={Styles.datePicker}
                    popperClassName={Styles.datePickerPopper}
                    customInput={<DatePickerCustomInput />}
                />
            </div>

            <TableWithoutButton columns={utilisationColumns} data={utilisationData} />  
        </> 
        : 
        <Loading/>

    );
}
