import React, { useState, useMemo, useEffect } from "react";
import TableWithoutButton from '../components/TableWithoutButton'

import { API, graphqlOperation } from 'aws-amplify';
import {queryEfficiency} from '../graphql/queries';

import TrafficLight from '../components/TrafficLight';

import ApplianceDailyData from '../components/ApplianceDailyData'
import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'

import "react-datepicker/dist/react-datepicker.css";

export default function Detail(props) {

    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    function todaysDateFormat(){
        let today = new Date()
        let todayYear = today.getFullYear()
        let todayMonth = today.getMonth()
        let todayDay = today.getDay()

        if(todayMonth < 10){
            todayMonth = '0' + todayMonth;
        }
        if(todayDay < 10){
            todayDay = '0' + todayDay;
        }

        let formattedDate = todayYear + '-' + todayMonth + '-' + todayDay

        return formattedDate
    }

    //GET USERS CURRENT TARIFF
    const [tableOpeningHoursData, setTableOpeningHoursData] = useState([]);
    const [tableWeekendWeekdayData, setTableWeekendWeekdayData] = useState([]);

    const [error, setError] = useState('');
    useEffect(() => {
        (async () => {
            let todaysDate = todaysDateFormat()
            try {
                const result = await API.graphql(
                    graphqlOperation(
                        queryEfficiency,
                        {
                            serial_number: serial,
                            carbon_multiplier: 0.21233,
                            max_date: todaysDate,
                            tariff: {tariff:{price_pence: 25, interval: {end_hour: 24, start_hour: 0}}},
                            off_peak_definitions: [
                                {name: "Power used outside peak standard working hours", threshold_pc: 20, off_peak_times: [{start_hour: 0, end_hour: 9}, {start_hour: 17.5, end_hour: 24}]}, 
                                {name: "Power used outside off-peak standard working hours", threshold_pc: 15, off_peak_times: [{start_hour: 0, end_hour: 8}, {start_hour: 18.5, end_hour: 24}]},
                                {name: "Power used outside building opening hours", threshold_pc: 15, off_peak_times: [{start_hour: 0, end_hour: 6}, {start_hour: 20, end_hour: 24}]},
                            ],
                            weekend_threshold_pc: 10,
                            weekend_days: [5, 6]
                        },
                    )
                );   
                console.log(result.data)

                let table1Data = [
                    {
                        type: result.data.queryEfficiency.data[0]['type'],
                        kwh: result.data.queryEfficiency.data[0]['lastDayKWh'].toFixed(2),
                        cost: result.data.queryEfficiency.data[0]['lastDayCostPounds'].toFixed(2),
                        carbon: result.data.queryEfficiency.data[0]['lastDayCostCarbonKgCO2e'].toFixed(2),
                        efficiency: result.data.queryEfficiency.data[0]['lastDayEfficiencyPercentage'] !== null ? result.data.queryEfficiency.data[0]['lastDayEfficiencyPercentage'].toFixed(0) + '%' : 'N/A',
                        efficient: result.data.queryEfficiency.data[0]['efficient'],
                        lastDay: result.data.queryEfficiency.data[0]['lastDayEfficient'],
                        lastWeek: result.data.queryEfficiency.data[0]['lastWeekEfficient'],
                        lastMonth: result.data.queryEfficiency.data[0]['lastMonthEfficient'],
                    },
                    {
                        type: result.data.queryEfficiency.data[1]['type'],
                        kwh: result.data.queryEfficiency.data[1]['lastDayKWh'].toFixed(2),
                        cost: result.data.queryEfficiency.data[1]['lastDayCostPounds'].toFixed(2),
                        carbon: result.data.queryEfficiency.data[1]['lastDayCostCarbonKgCO2e'].toFixed(2),
                        efficiency: result.data.queryEfficiency.data[1]['lastDayEfficiencyPercentage'] !== null ? result.data.queryEfficiency.data[1]['lastDayEfficiencyPercentage'].toFixed(0) + '%' : 'N/A',
                        efficient: result.data.queryEfficiency.data[1]['efficient'],
                        lastDay: result.data.queryEfficiency.data[1]['lastDayEfficient'],
                        lastWeek: result.data.queryEfficiency.data[1]['lastWeekEfficient'],
                        lastMonth: result.data.queryEfficiency.data[1]['lastMonthEfficient'],
                    },
                    {
                        type: result.data.queryEfficiency.data[2]['type'],
                        kwh: result.data.queryEfficiency.data[2]['lastDayKWh'].toFixed(2),
                        cost: result.data.queryEfficiency.data[2]['lastDayCostPounds'].toFixed(2),
                        carbon: result.data.queryEfficiency.data[2]['lastDayCostCarbonKgCO2e'].toFixed(2),
                        efficiency: result.data.queryEfficiency.data[2]['lastDayEfficiencyPercentage'] !== null ? result.data.queryEfficiency.data[2]['lastDayEfficiencyPercentage'].toFixed(0) + '%' : 'N/A',
                        efficient: result.data.queryEfficiency.data[2]['efficient'],
                        lastDay: result.data.queryEfficiency.data[2]['lastDayEfficient'],
                        lastWeek: result.data.queryEfficiency.data[2]['lastWeekEfficient'],
                        lastMonth: result.data.queryEfficiency.data[2]['lastMonthEfficient'],
                    }
                ]

                let table2Data = [
                    {
                        kwh: result.data.queryEfficiency.weekdays_weekend_data['lastWeekWastedKWh'].toFixed(2),
                        cost: result.data.queryEfficiency.weekdays_weekend_data['lastWeekCostPounds'].toFixed(2),
                        carbon: result.data.queryEfficiency.weekdays_weekend_data['lastWeekCostCarbonKgCO2e'].toFixed(2),
                        efficiency: result.data.queryEfficiency.weekdays_weekend_data['lastWeekEfficiencyPercentage'] !== null ? result.data.queryEfficiency.data[0]['lastDayEfficiencyPercentage'].toFixed(0) + '%' : 'N/A',
                        efficient: result.data.queryEfficiency.weekdays_weekend_data['lastWeekEfficient'],
                        lastWeek: result.data.queryEfficiency.weekdays_weekend_data['lastWeekEfficient'],
                        lastMonth: result.data.queryEfficiency.weekdays_weekend_data['lastMonthEfficient'],
                    }
                ]
                console.log(table1Data)
                setTableOpeningHoursData(table1Data)
                setTableWeekendWeekdayData(table2Data)
            }catch (err) {
                console.log(err)
                setError('Oops! Something went wrong! - Building opening hours efficiency: ' + err.errors[0].message)
                return
            }
            setError('')
        })();
    }, []);

    const EnergyEfficiencyTable1Columns = useMemo(
        () => [
            {
                Header: "Building opening hours efficiency",
                columns: [
                    {
                        Header:"Type",
                        accessor:"type",
                    },
                    {
                        Header:"Energy Efficient",
                        accessor:"efficient",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header:"Last 24 hours",
                        accessor:"lastDay",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header:"Last 7 days",
                        accessor:"lastWeek",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header:"Last 30 days",
                        accessor:"lastMonth",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header: "Last 24 hours Energy Wasted (kWh)",
                        accessor: "kwh",
                    },
                    {
                        Header:"Last 24 hours Cost Wasted (£)",
                        accessor:"cost",
                    },
                    {
                        Header:"Last 24 hours Carbon Wasted (kg CO2e)",
                        accessor:"carbon",
                    },
                    {
                        Header:"Energy Efficient Percentage",
                        accessor:"efficiency",
                    },
                ]
            },
        ],
        []
    )

    const EnergyEfficiencyTable2Columns = useMemo(
        () => [
            {
                Header: "Weekday versus weekend efficiency",
                columns: [
                    {
                        Header:"Energy Efficient",
                        accessor:"efficient",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header:"Last 7 days",
                        accessor:"lastWeek",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header:"Last 30 days",
                        accessor:"lastMonth",
                        disableSortBy:true,
                        Cell: ({cell}) => (<TrafficLight is_on={cell.value} />)
                    },
                    {
                        Header: "Last 7 days Energy Wasted (kWh)",
                        accessor: "kwh",
                    },
                    {
                        Header:"Last 7 days Cost Wasted (£)",
                        accessor:"cost",
                    },
                    {
                        Header:"Last 7 days Carbon Wasted (kg CO2e)",
                        accessor:"carbon",
                    },
                    {
                        Header:"Energy Efficient Percentage",
                        accessor:"efficiency",
                    },
                ]
            },
        ],
        []
    )

    return (
        <> 
            <PageTitle title='Energy Efficiency Checks'/>
            {/*------------------------------ HEADER ------------------------------*/}
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'EnergyEfficiency'}/>

            {/*------------------------------ TODAYS USAGE ------------------------------*/}
            <ApplianceDailyData serial={serial}/>

            {/*------------------------------ ERROR DISPLAY ------------------------------*/}
            {error !== '' ? 
                <>
                    <p style={{ fontSize:16, fontWeight:'600', color:'#FF5E15'}}>
                        {error}
                    </p>
                </>
            :
                <></>
            }

            {/*------------------------------ TABLES ------------------------------*/}
            {/* GROUP 1 */}
            <TableWithoutButton columns={EnergyEfficiencyTable1Columns} data={tableOpeningHoursData}/>  

            {/* GROUP 2 */}
            <TableWithoutButton columns={EnergyEfficiencyTable2Columns} data={tableWeekendWeekdayData}/>  

        </> 
    );
}









