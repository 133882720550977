import React from "react";
import Styles from './styles/pageTitle.module.scss';

export default function Detail(props) {

    return (
        <div className={Styles.PageTitleNav}>
            <p className={Styles.pageTitle}>
                {props.title}
            </p>

            <div className={Styles.seperator} />
        </div>
    );
}
