/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const addCurrency = /* GraphQL */ `
  mutation AddCurrency(
    $alpha_code: String
    $currency_name: String
    $locations: NewLocations
    $numeric_code: Int
  ) {
    addCurrency(
      alpha_code: $alpha_code
      currency_name: $currency_name
      locations: $locations
      numeric_code: $numeric_code
    ) {
      alpha_code
      currency_name
      is_default
      locations
      numeric_code
    }
  }
`;
export const addEnergyProduct = /* GraphQL */ `
  mutation AddEnergyProduct($energy_product: EnergyProductInput) {
    addEnergyProduct(energy_product: $energy_product) {
      contract_end
      contract_start
      currency
      is_fixed
      is_green
      product_id
      product_name
      standing_charges {
        apply_from
        standing_charge_type
        unit_rate
      }
      supplier_name
      tariffs {
        apply_from
        rate_type
        time_from
        time_to
        unit_rate
      }
    }
  }
`;
export const addEnergySupplier = /* GraphQL */ `
  mutation AddEnergySupplier(
    $company_number: String
    $domestic_supplier: Boolean
    $supplier_name: String
  ) {
    addEnergySupplier(
      company_number: $company_number
      domestic_supplier: $domestic_supplier
      supplier_name: $supplier_name
    ) {
      company_number
      display_name
      domestic_supplier
      supplier_name
    }
  }
`;
export const addIotPolicy = /* GraphQL */ `
  mutation AddIotPolicy($identity_id: String) {
    addIotPolicy(identity_id: $identity_id) {
      identity_id
    }
  }
`;
export const addOnboarding = /* GraphQL */ `
  mutation AddOnboarding(
    $appliance: ApplianceInput
    $device_model: String
    $group: String
    $onboarding_type: OnboardingType
    $owner_email: String
    $price_paid: Float
    $serial_number: String
  ) {
    addOnboarding(
      appliance: $appliance
      device_model: $device_model
      group: $group
      onboarding_type: $onboarding_type
      owner_email: $owner_email
      price_paid: $price_paid
      serial_number: $serial_number
    ) {
      appliance {
        age
        brand
        business
        business_name
        coordinates {
          accuracy
          altitude
          altitude_accuracy
          heading
          latitude
          longitude
          speed
        }
        floor
        location
        model
        nickname
        price_paid
        site
        type
      }
      device_model
      onboarding_type
      serial_number
    }
  }
`;
export const assignEnergyProduct = /* GraphQL */ `
  mutation AssignEnergyProduct($product_id: String, $serial_numbers: String) {
    assignEnergyProduct(
      product_id: $product_id
      serial_numbers: $serial_numbers
    ) {
      assigned_verv_connects {
        product_id
        serial_number
      }
    }
  }
`;
export const createNotificationConfig = /* GraphQL */ `
  mutation CreateNotificationConfig(
    $notification_config: NotificationConfigInput
  ) {
    createNotificationConfig(notification_config: $notification_config) {
      address
      checks_list
      id
      serials_list
      type
    }
  }
`;
export const deleteEnergyProduct = /* GraphQL */ `
  mutation DeleteEnergyProduct($product_id: String) {
    deleteEnergyProduct(product_id: $product_id) {
      product_id
    }
  }
`;
export const deleteEnergyTariff = /* GraphQL */ `
  mutation DeleteEnergyTariff($tariff_id: String) {
    deleteEnergyTariff(tariff_id: $tariff_id) {
      tariff_id
    }
  }
`;
export const deleteNotificationConfig = /* GraphQL */ `
  mutation DeleteNotificationConfig($notification_id: Int) {
    deleteNotificationConfig(notification_id: $notification_id) {
      id
    }
  }
`;
export const purgeEnergyProduct = /* GraphQL */ `
  mutation PurgeEnergyProduct($product_id: String) {
    purgeEnergyProduct(product_id: $product_id) {
      product_id
    }
  }
`;
export const switchOff = /* GraphQL */ `
  mutation SwitchOff($serial_number: String) {
    switchOff(serial_number: $serial_number) {
      serial_number
      update {
        state
      }
    }
  }
`;
export const switchOn = /* GraphQL */ `
  mutation SwitchOn($serial_number: String) {
    switchOn(serial_number: $serial_number) {
      serial_number
      update {
        state
      }
    }
  }
`;
export const updateEnergyProduct = /* GraphQL */ `
  mutation UpdateEnergyProduct(
    $product_id: String
    $standing_charge: Float
    $tariffs: [NewTariff]
  ) {
    updateEnergyProduct(
      product_id: $product_id
      standing_charge: $standing_charge
      tariffs: $tariffs
    ) {
      contract_end
      contract_start
      currency
      is_fixed
      is_green
      product_id
      product_name
      standing_charges {
        apply_from
        standing_charge_type
        unit_rate
      }
      supplier_name
      tariffs {
        apply_from
        rate_type
        time_from
        time_to
        unit_rate
      }
    }
  }
`;
export const updateNotificationConfig = /* GraphQL */ `
  mutation UpdateNotificationConfig(
    $notification_config: NotificationConfigInput
  ) {
    updateNotificationConfig(notification_config: $notification_config) {
      address
      checks_list
      id
      serials_list
      type
    }
  }
`;
