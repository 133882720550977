import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Graph = ({fields, data}) =>  {
  
    const [arr,setArr] = useState([]);

    const [field, setField] = useState('RealPower');
    const [field2, setField2] = useState('ApparentPower');

    const [fieldLabel, setFieldLabel] = useState('Real Power W');
    const [field2Label, setField2Label] = useState('Apparent Power VA');

    const validate = (value, value2) => {
        setArr((prevState)=>{
            if(prevState.length > 60){
                prevState.splice(0,prevState.length-60);
            }
            const newValue = { Name: (new Date().toISOString().substr(11, 8))};
            newValue[field] = value;
            newValue[field2] = value2;

            handleLabel(field, 1)
            handleLabel(field2, 2)

            return( [...prevState,newValue]);
        });
    }

    useEffect(() => {
        validate(parseFloat(data[0]?.[field].split('W').join('')), parseFloat(data[0]?.[field2].split('W').join('')));
      },[data]);

    
    const handleSelect = (eve) => {
      setField(eve.target.value);
    }

    const handleSelect2 = (eve) => {
      setField2(eve.target.value);
    }

    const handleLabel = (field, whichOne) => {
      if(field === 'RealPower'){
        if(whichOne===1){
          setFieldLabel('Real Power (W)')
        }
        if(whichOne===2){
          setField2Label('Real Power (W)')
        }
        return
      }

      if(field === 'ApparentPower'){
        if(whichOne===1){
          setFieldLabel('Apparent Power (VA)')
        }
        if(whichOne===2){
          setField2Label('Apparent Power (VA)')
        }
        return
      }

      if(field === 'Current'){
        if(whichOne===1){
          setFieldLabel('Current (A)')
        }
        if(whichOne===2){
          setField2Label('Current (A)')
        }
        return
      }

      if(field === 'PowerFactor'){
        if(whichOne===1){
          setFieldLabel('Power Factor (W)')
        }
        if(whichOne===2){
          setField2Label('Power Factor (W)')
        }
        return
      }

      if(field === 'Voltage'){
        if(whichOne===1){
          setFieldLabel('Voltage (V)')
        }
        if(whichOne===2){
          setField2Label('Voltage (V)')
        }
        return
      }

    }

//Custom tooltip (WHEN HOVERING ON THE BARS)
const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
  return (
      <div style={{backgroundColor:'rgba(255, 255, 255,.9)', padding:10, borderRadius:15, }}>

          <p style={{fontWeight:'bold', margin:0, padding:0, }}>{`${label}`}</p>

          {payload.length === 1 ? 
          <div>
              <p style={{display:'inline', color: payload[0]?.color}}>{`${payload[0]?.name}: `}</p>
              <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0]?.value}\n`}</p>
          </div>

          : payload.length === 2 ?
          <>
          <div>
              <p style={{display:'inline', color: payload[0]?.color}}>{`${payload[0]?.name}: `}</p>
              <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0]?.value}\n`}</p>
          </div>

          <div>
              <p style={{display:'inline', color: payload[1]?.color}}>{`${payload[1]?.name}: `}</p>
              <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[1]?.value}\n`}</p>
          </div>
          </>
          :
          <></>
        }
      </div>
  );
  }
  return null;
};
    return (
      <>

      <div className='graphView'>
        <div className="filter">
              <label>
                  <select id="browsers" onChange={handleSelect} value={field}>
                      {fields.map((value, index) => <option  key={value.accessor} value={value.accessor}>{'← ' + value.Header}</option>)}
                  </select>

                  Filter by field

                  <select id="browsers" onChange={handleSelect2} value={field2}>
                      {fields.map((value, index) => <option  key={value.accessor} value={value.accessor}>{'→ ' + value.Header}</option>)}
                  </select>
              </label>
          </div>

        <ResponsiveContainer width="100%" height={600}>

          <LineChart
              width={960}
              height={500}
              data={arr}
              margin={{
                top: 50,
                right: 10,
                left: 10,
                bottom: 20
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis dataKey="Name" stroke='#34485b' />

              <YAxis yAxisId="left"  label={{ value: fieldLabel, angle: -90, position: 'insideLeft', }} stroke="#319e9b" />
              <YAxis yAxisId="right" orientation="right" label={{ value: field2Label, angle: 90, position: 'insideRight' }} stroke="#b85a68" />

              <Tooltip content={<CustomTooltip/>} />

              <Legend wrapperStyle={{bottom:-10, marginBottom:10}} />

              <Line yAxisId="left" type="monotone" strokeWidth='3' dataKey={field} stroke="#319e9b" isAnimationActive={true}/>
              <Line yAxisId="right" type="monotone" strokeWidth='3' dataKey={field2} stroke="#b85a68" isAnimationActive={true}/>

          </LineChart>
        </ResponsiveContainer>
        </div>
        </>
    );
}

export default Graph;

