import React, { useState, useMemo, useEffect } from "react";
import Amplify, {Auth} from 'aws-amplify';
import { API, graphqlOperation } from 'aws-amplify';
import Graph from '../components/graph';
import Graph3Phase from '../components/graph3Phase';
import Styles from './styles/detail.module.scss';
import { AWSIoTProvider } from '@aws-amplify/pubsub';
import { addIotPolicy } from "../graphql/mutations";

import ApplianceDailyData from '../components/ApplianceDailyData'
import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'
import LiveDataDisplay from '../components/liveDataDisplay'

import ApplianceNotTurnedOnMessage from '../components/ApplianceNotTurnedOnMessage'

export default function Detail(props) {
    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    const [graphData, setGraphData] = useState(null);

    Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: 'eu-west-2',
        aws_pubsub_endpoint: 'wss://ay0g0knprqz3x-ats.iot.eu-west-2.amazonaws.com/mqtt',
    }));

    const attachUser = async () => {
        const user = await Auth.currentUserInfo();
        // call mutation
        const policy_cert = await API.graphql(graphqlOperation( addIotPolicy, {identity_id:user.id}));
        if(policy_cert.data){
            window.location.reload();
        }
    }

    useEffect(() => {
        const sub = Amplify.PubSub.subscribe(`live/${serial}`).subscribe({
            next: graphData => {
                setGraphData([graphData.value]);
                console.log([graphData.value])
            },
            error: async error => {
                sub.unsubscribe();
                if(error.error.errorCode === 8){
                    //policy error - try and attach
                    attachUser();
                } else {
                    console.error(error);
                }
            },
            close: () => console.log('Closed Connection'),
        });

        return () => {
            sub.unsubscribe();
        }
    },[])

    const columns = useMemo(
        () => [
        {
            // first group - TV Show
            Header: "Your Connected Device",
            // First group columns
            columns: [
            {
                Header: "Apparent Power",
                accessor: "ApparentPower"
            },
            {
                Header: "Current",
                accessor: "Current"
            },
            {
                Header: "Power Factor",
                accessor: "PowerFactor"
            },
            {
                Header:"Real Power",
                accessor:"RealPower",
            },
            {
                Header: "Voltage",
                accessor: "Voltage",
            },
            ]
        },
        ],
        []
    );

    return (
        <>
        {graphData ?
        <> 
            <PageTitle title='Live Data'/>
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'LivePower'}/>

            {/*------------------------------ TODAYS USAGE ------------------------------*/}
            <ApplianceDailyData serial={serial}/>

            {/*------------------------------ TODAYS USAGE SINGLE and 3 PHASE ------------------------------*/}
            {(serial !== '3-phase-emc-03') && (serial !== '3-phase-emc-04') ? 
            <LiveDataDisplay 
                singlePhase={true}
                ApparentPower={graphData[0]['ApparentPower']}
                Current={graphData[0]['Current']}
                PowerFactor={graphData[0]['PowerFactor']}
                RealPower={graphData[0]['RealPower']}
                Voltage={graphData[0]['Voltage']}
            />
            :
            <LiveDataDisplay 
                singlePhase={false}

                ApparentPowerL1={graphData[0]['ApparentPower L1']}
                ApparentPowerL2={graphData[0]['ApparentPower L2']}
                ApparentPowerL3={graphData[0]['ApparentPower L3']}

                CurrentL1={graphData[0]['Current L1']}
                CurrentL2={graphData[0]['Current L2']}
                CurrentL3={graphData[0]['Current L3']}

                PowerFactorL1={graphData[0]['PowerFactor L1']}
                PowerFactorL2={graphData[0]['PowerFactor L2']}
                PowerFactorL3={graphData[0]['PowerFactor L3']}

                RealPowerL1={graphData[0]['RealPower L1']}
                RealPowerL2={graphData[0]['RealPower L2']}
                RealPowerL3={graphData[0]['RealPower L3']}

                VoltageL1={graphData[0]['Voltage L1']}
                VoltageL2={graphData[0]['Voltage L2']}
                VoltageL3={graphData[0]['Voltage L3']}
            />
            }
            {/*------------------------------ LIVE DATA ------------------------------*/}
            {(serial !== '3-phase-emc-03') && (serial !== '3-phase-emc-04') ? 
            <Graph fields={columns[0].columns} data={graphData}/>
            :
            <Graph3Phase fields={columns[0].columns} data={graphData}/>
            }
        </> 
            :
            <ApplianceNotTurnedOnMessage/>
        }
        </>

    );
}
