import React, { useState } from "react";

import Styles from './styles/password.module.scss';
import PageTitle from '../components/PageTitle'

import { Auth } from 'aws-amplify';

export default function Tarrifs(props) {

    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [repeatNewPassword, setRepeatNewPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    //THE ERROR HANDLE 
    const [error, setError] = useState('');
    //SUCCESS DISPLAY
    const [successChange, setSuccessChange] = useState('');

    function checkInputs(){

        if(oldPassword.length <  5){
            setError('The entered current password is too short.')
            return
        }

        if(newPassword.length <  5){
            setError('The entered new password is too short.')
            return
        }

        if(newPassword !==  repeatNewPassword){
            setError('The entered new passwords do not match.')
            return
        }
        
        changePass()
    }

    const changePass = () => {
        Auth.currentAuthenticatedUser()
        .then(user => {
            return Auth.changePassword(user, oldPassword, newPassword);
        })
        .then(data => console.log(data))
        .then(data => setSuccessChange('Your password has changed.'), setError(''))
        .catch(err => setError(err.message), setSuccessChange(''));
    }

    return (
        <div style={{alignContent:'center', textAlign:'left', scrollBehavior:'smooth', width:'100%'}} >
            <PageTitle title='Change Password'/>

            <div className={Styles.currentTariffBox}>
                <div className={Styles.box}>
                    <div className={Styles.labelPassword}>
                        <label>
                            Old password
                        </label>
                        {showPassword === false ? 
                        <input type="password"  className={Styles.inputPassword} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} name="fname"/>
                        :
                        <input type="text"  className={Styles.inputPassword} value={oldPassword} onChange={(e) => setOldPassword(e.target.value)} name="fname"/>
                        }
                    </div>

                    <div className={Styles.seperator} />

                    <div className={Styles.labelPassword}>
                        <label>
                            New password
                        </label>
                        {showPassword === false ? 
                        <input type="password"  className={Styles.inputPassword} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} name="fname"/>
                        :
                        <input type="text"  className={Styles.inputPassword} value={newPassword} onChange={(e) => setNewPassword(e.target.value)} name="fname"/>
                        }
                    </div>

                    <div className={Styles.labelPassword}>
                        <label>
                            Repeat new password
                        </label>
                        {showPassword === false ? 
                        <input type="password" className={Styles.inputPassword} value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} name="fname"/>
                        :
                        <input type="text"  className={Styles.inputPassword} value={repeatNewPassword} onChange={(e) => setRepeatNewPassword(e.target.value)} name="fname"/>
                        }
                    </div>
    
                    <div className={Styles.seperator} />

                    <div className={Styles.labelPassword}>
                        <label>
                            Show Password
                        </label>
                        <input type="checkbox" className={Styles.checkBox} value={showPassword} onChange={()=>setShowPassword(!showPassword)} />
                    </div>

                    <div className={Styles.seperator} />

                    <p style={{ fontSize:16, fontWeight:'600',}}>
                        Your password must have a minimum of 8 characters, include a capital letter, a number, a symbol and not be identical to your account name or email address.
                    </p>

                    <div className={Styles.seperator} />

                </div>

                {error !== '' ? 
                    <>

                        <p style={{ fontSize:24, fontWeight:'600', color:'#FF5E15'}}>
                            {error}
                        </p>

                        <div className={Styles.seperator} />
                    </>
                :
                    <></>
                }

                {successChange !== '' ? 
                    <>
                        <div className={Styles.seperator} />

                        <p style={{ fontSize:24, fontWeight:'600', color:'#2BBCB9'}}>
                            {successChange}
                        </p>

                        <div className={Styles.seperator} />
                    </>
                :
                    <></>
                }

                <button className={Styles.ButtonSave} onClick={checkInputs} >
                    Save
                </button>

            </div>

        </div>
    );
}

