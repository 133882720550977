/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const queryCurrencies = /* GraphQL */ `
  query QueryCurrencies {
    queryCurrencies {
      currencies {
        alpha_code
        currency_name
        is_default
        locations
        numeric_code
      }
    }
  }
`;
export const queryEfficiency = /* GraphQL */ `
  query QueryEfficiency(
    $carbon_multiplier: Float
    $max_date: String
    $n_units_1_day_detail: Int
    $n_units_4_week_detail: Int
    $off_peak_definitions: [OffPeakDefinition]
    $serial_number: String!
    $tariff: TariffType2
    $weekend_days: String
    $weekend_threshold_pc: Float
  ) {
    queryEfficiency(
      carbon_multiplier: $carbon_multiplier
      max_date: $max_date
      n_units_1_day_detail: $n_units_1_day_detail
      n_units_4_week_detail: $n_units_4_week_detail
      off_peak_definitions: $off_peak_definitions
      serial_number: $serial_number
      tariff: $tariff
      weekend_days: $weekend_days
      weekend_threshold_pc: $weekend_threshold_pc
    ) {
      data {
        efficient
        off_peak_definition
        tariff
        carbon_multiplier
        type
        lastDayEfficient
        lastDayNFailures
        lastDayNSkipped
        lastDayKWh
        lastDayTotalKWh
        lastDayEfficiencyPercentage
        lastDayCostPounds
        lastDayCostCarbonKgCO2e
        lastWeekEfficient
        lastWeekNFailures
        lastWeekNSkipped
        lastWeekKWh
        lastWeekTotalKWh
        lastWeekEfficiencyPercentage
        lastWeekCostPounds
        lastWeekCostCarbonKgCO2e
        lastMonthEfficient
        lastMonthNFailures
        lastMonthNSkipped
        lastMonthKWh
        lastMonthTotalKWh
        lastMonthEfficiencyPercentage
        lastMonthCostPounds
        lastMonthCostCarbonKgCO2e
      }
      efficiency_data_main_page {
        efficient
        off_peak_definition
        tariff
        carbon_multiplier
        type
        lastDayEfficient
        lastDayNFailures
        lastDayNSkipped
        lastDayKWh
        lastDayTotalKWh
        lastDayEfficiencyPercentage
        lastDayCostPounds
        lastDayCostCarbonKgCO2e
        lastWeekEfficient
        lastWeekNFailures
        lastWeekNSkipped
        lastWeekKWh
        lastWeekTotalKWh
        lastWeekEfficiencyPercentage
        lastWeekCostPounds
        lastWeekCostCarbonKgCO2e
        lastMonthEfficient
        lastMonthNFailures
        lastMonthNSkipped
        lastMonthKWh
        lastMonthTotalKWh
        lastMonthEfficiencyPercentage
        lastMonthCostPounds
        lastMonthCostCarbonKgCO2e
      }
      efficiency_data_4_week_detail {
        type
        off_peak_definition
        tariff
        carbon_multiplier
        time_unit
        metrics_by_time_unit {
          start_time
          end_time
          step_days
          step_num
          Efficient
          NFailures
          NSkipped
          KWh
          TotalKWh
          EfficiencyPercentage
          CostPounds
          CostCarbonKgCO2e
        }
      }
      efficiency_data_1_day_detail {
        type
        off_peak_definition
        tariff
        carbon_multiplier
        time_unit
        metrics_by_time_unit {
          start_time
          end_time
          step_days
          step_num
          Efficient
          NFailures
          NSkipped
          KWh
          TotalKWh
          EfficiencyPercentage
          CostPounds
          CostCarbonKgCO2e
        }
      }
      weekdays_weekend_data {
        lastWeekEfficient
        lastMonthEfficient
        lastWeekWastedKWh
        lastMonthWastedKWh
        lastWeekCostCarbonKgCO2e
        lastMonthCostCarbonKgCO2e
        lastWeekEfficiencyPercentage
        lastMonthEfficiencyPercentage
        lastWeekCostPounds
        lastMonthCostPounds
      }
    }
  }
`;
export const queryEnergyProductBySerial = /* GraphQL */ `
  query QueryEnergyProductBySerial($serial_number: String) {
    queryEnergyProductBySerial(serial_number: $serial_number) {
      contract_end
      contract_start
      currency
      is_fixed
      is_green
      product_id
      product_name
      standing_charges {
        apply_from
        standing_charge_type
        unit_rate
      }
      supplier_name
      tariffs {
        apply_from
        rate_type
        time_from
        time_to
        unit_rate
      }
    }
  }
`;
export const queryEnergyProducts = /* GraphQL */ `
  query QueryEnergyProducts {
    queryEnergyProducts {
      energy_products {
        contract_end
        contract_start
        currency
        is_fixed
        is_green
        product_id
        product_name
        standing_charges {
          apply_from
          standing_charge_type
          unit_rate
        }
        supplier_name
        tariffs {
          apply_from
          rate_type
          time_from
          time_to
          unit_rate
        }
      }
    }
  }
`;
export const queryEnergySuppliers = /* GraphQL */ `
  query QueryEnergySuppliers($defaults_only: Boolean) {
    queryEnergySuppliers(defaults_only: $defaults_only) {
      energy_suppliers {
        company_number
        display_name
        domestic_supplier
        supplier_name
      }
    }
  }
`;
export const queryNotificationConfigs = /* GraphQL */ `
  query QueryNotificationConfigs {
    queryNotificationConfigs {
      notification_configs {
        address
        checks_list
        id
        serials_list
        type
      }
    }
  }
`;
export const queryPowerUsed = /* GraphQL */ `
  query QueryPowerUsed(
    $intervalSize: IntervalSize
    $powerAggregationType: powerAggregationType
    $powerCalculationType: powerCalculationType
    $powerCalculationsRange: DateTimeRange
    $serial_number: String!
  ) {
    queryPowerUsed(
      intervalSize: $intervalSize
      powerAggregationType: $powerAggregationType
      powerCalculationType: $powerCalculationType
      powerCalculationsRange: $powerCalculationsRange
      serial_number: $serial_number
    ) {
      preCalculations {
        aggregation_type
        average_amps
        end_date
        end_time
        start_date
        start_time
        tariff
        timestamp
        total_carbon
        total_cost
        total_kvah
        total_kwh
      }
    }
  }
`;
export const queryUtilisation = /* GraphQL */ `
  query QueryUtilisation(
    $day: String
    $month: String
    $serial_number: String
    $year: String
  ) {
    queryUtilisation(
      day: $day
      month: $month
      serial_number: $serial_number
      year: $year
    ) {
      utilisation {
        off_at
        on_at
        total_kwh
      }
    }
  }
`;
export const queryVervConnects = /* GraphQL */ `
  query QueryVervConnects(
    $count: Int
    $last_s3_item: Boolean
    $serial_number: String
    $start: Int
  ) {
    queryVervConnects(
      count: $count
      last_s3_item: $last_s3_item
      serial_number: $serial_number
      start: $start
    ) {
      items {
        appliance {
          age
          brand
          business
          business_name
          coordinates {
            accuracy
            altitude
            altitude_accuracy
            heading
            latitude
            longitude
            speed
          }
          floor
          location
          model
          nickname
          price_paid
          site
          type
        }
        device_type
        group
        is_on
        is_receiving_data
        last_connection
        last_s3_item
        last_used
        operational_power_used
        received_within_1w
        received_within_24
        role
        s3_within_1w
        s3_within_24h
        s3_within_28
        serial
        used_within_1w
        used_within_24h
        used_within_28
        utilisation_count
      }
    }
  }
`;
