// Table.js
import React, { useEffect, useState, useMemo } from "react";
import Table from '../components/Table';
import Amplify from 'aws-amplify';
import TrafficLight from '../components/TrafficLight';
import LastS3Item from '../components/LastS3Item';
import MomentDate from '../components/MomentDate';

import {queryVervConnects} from '../graphql/queries';
import { API } from 'aws-amplify';
import { AWSIoTProvider } from '@aws-amplify/pubsub';

import Loading from '../components/Loading'
import NoVervConnectsMessage from '../components/NoVervConnectsMessage'


export default function Home() {
    Amplify.addPluggable(new AWSIoTProvider({
        aws_pubsub_region: 'eu-west-2',
        aws_pubsub_endpoint: 'wss://ay0g0knprqz3x-ats.iot.eu-west-2.amazonaws.com/mqtt',
    }));

    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    // Using useEffect to call the API once mounted and set the data
    useEffect(() => {
        dataLoad()
    }, []);

    async function dataLoad(){

        const result = await API.graphql({ query: queryVervConnects })
        let vervData = result.data.queryVervConnects.items
        await Promise.all(vervData.map( (item) => faultStatusAppend(item)))
        async function faultStatusAppend(item) {
            await fetch('http://ad8840cf528664e2097a89b799e2f859-680080027.eu-west-2.elb.amazonaws.com/status/' + item.serial)
            .then(res => res.json())
            .then(
            (result) => {
                let fault
                result !== null ? fault = result.is_fault : fault = false
                item.is_fault = fault
            },
            (error) => {
                //HAVE TO REMOVE THIS 
                console.log(error)
            }
            )
        }
        setData(vervData);
        setLoading(false)
    }

    let columns = useMemo(
        () => [
            {
                Header: () => null, // No header
                id: 'expander', 
                Cell: ({ row }) => (
                    <span style={{color:'##34485A'}}>
                        {row.isExpanded ? '▼' : '►'}
                    </span>
                ),
            },
            {
            Header: "Devices",
            // First group columns
            columns: [
                {
                    Header: "Nickname",
                    accessor: row => row.appliance.nickname
                },
                {
                    id: 'site',
                    Header: "Site",
                    accessor: row => row.appliance.site
                },
                {
                    Header: "Location",
                    accessor: row => row.appliance.location
                },
                {
                    Header:"Type",
                    accessor: row => row.appliance.type
                },
                {
                    Header: "Last Used",
                    accessor: "last_used",
                    Cell: ({cell, row}) => (<MomentDate value={cell.value} />)
                },
                {
                    Header: "24H",
                    accessor: "used_within_24h",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    Header: "1W",
                    accessor: "used_within_1w",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    Header: "28D",
                    accessor: "used_within_28",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    Header: "Times Used",
                    accessor:'utilisation_count'
                },
                {
                    Header:"Energy Efficient",
                    accessor:"energy",
                    disableSortBy:true,
                    Cell: ({cell}) => (<TrafficLight is_on={true} />)
                },
                {
                    Header:"Fault",
                    accessor:"is_fault",
                    Cell: ({cell}) => ([<TrafficLight is_on={!cell.value}/>])
                },
                {
                    Header: "Total Power Used",
                    accessor: "operational_power_used",
                    Cell: ({cell}) => (<p>{(cell.value/1000).toFixed(2)} KWh</p>)
                },
                {
                    Header: "Last Message Received",
                    accessor: "last_connection",
                    Cell: ({cell, row}) => (<MomentDate value={cell.value} />)
                },
                {
                    Header: "24H",
                    accessor: "received_within_24",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    Header: "1W",
                    accessor: "received_within_1w",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    Header: "28D",
                    accessor: "received_within_28",
                    Cell: ({cell, row}) => (<TrafficLight is_on={cell.value} />)
                },
                {
                    id: 'serial',
                    Header: "serial",
                    accessor: "serial",
                },
                {
                    id: 'S3',
                    Header: "Last S3 Item",
                    accessor: "serial",
                    Cell: props => (<LastS3Item Props={props} />)
                },
                {
                    id: 'S3-24-hours',
                    Header: "24H",
                    accessor: "serial",
                    Cell: props => (<LastS3Item Props={props} />)
                },
                {
                    id: 'S3-1-week',
                    Header: "1W",
                    accessor: "serial",
                    Cell: props => (<LastS3Item Props={props} />)
                },
                {
                    id: "S3-28-days",
                    Header: "28D",
                    accessor: "serial",
                    Cell: props => (<LastS3Item Props={props} />)
                },
            ]
        },
        ],
        []
    );
    


    if (data === null || loading === true) {
        return <Loading/>;
    } else if (data.length === 0) {
        return <NoVervConnectsMessage/>;
    } else {
        return <Table columns={columns} data={data} withSearch={true} />
    }

}
