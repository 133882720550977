
import React, { useEffect, useState } from "react";
import logo from './navy_logo.png';
import { Auth } from 'aws-amplify';
import {withAuthenticator } from '@aws-amplify/ui-react';
import { motion } from 'framer-motion';
// import { useHistory } from "react-router-dom";

import Home from './views/home';
import Detail from './views/detail';
import Information from './views/information';
import HistoryPage from './views/history';
import Tariffs from './views/Tariffs';
import Notifications from './views/notifications';
import ChangePassword from './views/changePassword';
import Timeline from './views/timeline';
import EnergyEfficiency from './views/energyEfficiency';
import DeviceCompare from './views/deviceCompare';

import FaultDetection from './views/faultDetection';

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";

import './App.scss';

function App() {
    //Use history for navigation between the pages
    // let history = useHistory();
    //Function for navigating to different page
    const navigateTo = (location) => {
        window.location = location
    }

    //Current user information will be stored here
    const [user, setUser] = useState(null);
    //Load user information 
    useEffect(() => {
        const fetch = async () => {
            const user = await Auth.currentUserInfo();
            setUser(user);
        }
        fetch();
    },[])

    //Toggle for navigation bar buttons - to show/hide the drop down menus
    const [showAccount, setShowAccount] = useState(false);
    const [showSettings, setShowSettings] = useState(false);
    //Toggle Functions for navigation bar buttons - to show/hide the drop down menus
    const toggleMenu = () => {
        setShowAccount(!showAccount);
        if(showAccount === false){ setShowSettings(false) }
    }
    const toggleSettings = () => {
        setShowSettings(!showSettings);
        if(showSettings === false){ setShowAccount(false) }
    }

    //Navigating to the home page
    const goHome = () => {
        window.location.href = '/'
    }

    //User sign out function
    const signOut = async () => {
        try {
            await Auth.signOut();
            window.location.href = '/';
        } catch (error) {
            console.log('error signing out: ', error);
        }
    }

return (
    <div className="App">
        <header>
            <nav>
            <a href="/"><motion.img animate={{ y:0, opacity:1 }}  transition={{ duration: 1 }} initial={{opacity:0}} src={logo} alt="logo" /></a>
            {user &&
                <> 
                <div className="navButtons">
                    <div className="account">
                        <h2 onClick={goHome} className={'buttonText'}>HOME</h2>
                    </div>

                    <div className="account"
                        onMouseEnter={e => {
                            toggleMenu()
                        }}
                        onMouseLeave={e => {
                            toggleMenu()
                        }}
                    >
                        <h2 onClick={toggleMenu} className={'buttonText'}>ACCOUNT<div className={`arrow ${showAccount ? 'show':''}`}></div></h2>
                        {showAccount !== false ? 
                            <div className={`details ${showAccount ? 'show':''}`}>
                                <dl>
                                    <dt>Email: </dt>
                                    <dd>{user.attributes?.email}</dd>
                                    {/* <dt>Phone: </dt>
                                    <dd>{user?.attributes?.phone_number}</dd> */}
                                </dl>
                                <hr/>
                                <button onClick={signOut}>Sign Out</button>
                            </div>
                        :
                            <></>
                        }
                    </div>

                    <div className="account" 
                        onMouseEnter={e => {
                            toggleSettings()
                        }}
                        onMouseLeave={e => {
                            toggleSettings()
                        }}
                    >
                        <h2 className={'buttonText'}>SETTINGS<div className={`arrow ${showSettings ? 'show':''}`}></div></h2>
                        {showSettings !== false ? 
                            <div className={`details ${showSettings ? 'show':''}`}>
                                <ul>
                                    <li><a href="/tariffs" onClick={ () => navigateTo('/tariffs')}>Your tariffs</a></li>
                                    <hr/>
                                    <li><a href="/notifications" onClick={ () => navigateTo('/notifications')}>Notifications</a></li>
                                    <hr/>
                                    <li><a href="/change-password" onClick={ () => navigateTo('/change-password')}>Change Password</a></li>
                                </ul>                            
                            </div>
                        :
                            <></>
                        }
                    </div>

                </div>
                </>
            }
            </nav>

    </header>

    <main>
        <Router>
            <Switch>
                <Route path="/" exact>
                    <Home />
                </Route>

                <Route path="/live/:serial">
                    <Detail />
                </Route>

                <Route path="/info/:serial">
                    <Information />
                </Route>

                <Route path="/history/:serial">
                    <HistoryPage/>
                </Route>

                <Route path="/device-compare/:serial">
                    <DeviceCompare/>
                </Route>

                <Route path="/tariffs">
                    <Tariffs />
                </Route>

                <Route path="/notifications">
                    <Notifications />
                </Route>

                <Route path="/change-password">
                    <ChangePassword />
                </Route>
                
                <Route path="/timeline">
                    <Timeline />
                </Route>

                <Route path="/energy-efficiency/:serial">
                    <EnergyEfficiency/>
                </Route>

                <Route path="/fault-detection/:serial">
                    <FaultDetection/>
                </Route>
            </Switch>
        </Router>
    </main>

    <footer>

    </footer>
    </div>
    );
}

export default withAuthenticator(App)
