import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);


function MomentDate({value,row}) {
    const isValid = dayjs(value).isValid()

    return (
        <span style={{fontSize:12}}>{isValid ? dayjs(value).calendar() : '–'}</span>
    )
    }

export default MomentDate
