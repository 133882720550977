// Table.js
import React, { useState } from "react";
import { useFilters, useSortBy, useTable, useExpanded } from "react-table";
import { useHistory } from "react-router-dom";
import styles from './styles/table.module.scss';
import { motion } from 'framer-motion';

export default function Table({ columns, data, withSearch }) {

    let history = useHistory();

    // Create a state
    const [filterInput, setFilterInput] = useState("");
    const [filterSerial, setFilterSerial] = useState("");

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow,
        state: { expanded },
        visibleColumns,
        setFilter // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({columns,data, initialState:{hiddenColumns:[]}},useFilters, useSortBy, useExpanded);

    // Update the state when input changes
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilter("site", value); // Update the serial filter. Now our table will filter and show only the rows which have a matching value
        setFilterInput(value);
    };

    // Update the state when input changes
    const handleSerialChange = e => {
        const value = e.target.value || undefined;
        setFilter("serial", value); // Update the serial filter. Now our table will filter and show only the rows which have a matching value
        setFilterSerial(value);
    };

    const LiveClicked = (serial) => {
        history.push(`/live/${serial}`);
    }

    const InfoClicked = (serial) => {
        history.push(`/info/${serial}`);
    }

    const HistoryClicked = (serial) => {
        history.push(`/history/${serial}`);
    }

    const DeviceCompareClicked = (serial) => {
        history.push(`/device-compare/${serial}`);
    }
    
    const TimelineClicked = (serial) => {
        history.push(`/timeline/${serial}`);
    }

    const energyEfficiencyClicked = (serial) => {
        history.push(`/energy-efficiency/${serial}`);
    }

    const faultDetectionClicked = (serial) => {
        history.push(`/fault-detection/${serial}`);
    }

/* 
Render the UI for your table
- react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
*/
return (
    <>
    {withSearch ?
    <>
        <div className={styles.meta}>
            <motion.label animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}}>
                Search Site
                <input
                    className={styles.input}
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Device Site..."}
                />
            </motion.label>
        </div>
    
        <div className={styles.meta}>
            <motion.label animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}}>
                Search Serial
                <input
                    className={styles.input}
                    value={filterSerial}
                    onChange={handleSerialChange}
                    placeholder={"Plug Serial..."}
                />
            </motion.label>
        </div>
    </>
    : null }


    <motion.table 
        animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}} className={styles.table} {...getTableProps()}>
        
        <thead>
            {headerGroups.map(headerGroup => (

                <tr {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map(column => (

                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>

                            <span style={{verticalAlign: 'middle'}}>{column.render("Header")}</span>
                            <br/>
                            {column.isSorted ? 
                            <span style={{width:'15px',height:'15px',display:'inline-block',verticalAlign: 'middle'}}>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? '↓'
                                    : '↑'
                                : ''}
                            </span>
                            :<></>
                            }

                        </th>

                    ))}

                </tr>

            ))}
        </thead>

        <tbody {...getTableBodyProps()}>

            {rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();

                return (
                    <React.Fragment key={rowProps.key}>
                        <tr {...row.getToggleRowExpandedProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td> ;
                            })}
                        </tr>

                        {/*
                        If the row is in an expanded state, render a row with a
                        column that fills the entire length of the table.
                        */}
                        {row.isExpanded ? (
                        <tr>
                            <td colSpan={visibleColumns.length}>
                            {/*
                                Inside it, call our renderRowSubComponent function. In reality,
                                you could pass whatever you want as props to
                                a component like this, including the entire
                                table instance. But for this example, we'll just
                                pass the row
                            */}
                                <button className={styles.tableButton} type="submit" onClick={() => LiveClicked(row.values.serial)}>Live Data</button>
                                <button className={styles.tableButton} type="submit" onClick={() => window.open("https://visualise.ci.verv.energy/")}>High Speed Data</button> 
                                {/* <button className={styles.tableButton} type="submit" onClick={() => window.open("https://ers.connect.verv.energy/")}>Energy Report</button> */}
                                <button className={styles.tableButton} type="submit" onClick={() => HistoryClicked(row.values.serial)}>History</button>
                                <button className={styles.tableButton} type="submit" onClick={() => DeviceCompareClicked(row.values.serial)}>Compare Devices</button>
                                <button className={styles.tableButton} type="submit" onClick={() => TimelineClicked(row.values.serial)}>Timeline</button>
                                {/* <button type="submit">Costs</button>*/}
                                <button className={styles.tableButton} type="submit" onClick={() => energyEfficiencyClicked(row.values.serial)}>Energy Efficiency</button>
                                {/* <button className={styles.tableButton} type="submit" onClick={() => faultDetectionClicked(row.values.serial)}>Fault Detection</button> */}
                                <button className={styles.tableButton} type="submit" onClick={() => InfoClicked(row.values.serial)}>More Info</button>

                            </td>
                        </tr>
                        ) : null}

                    </React.Fragment>
                );
            })}

        </tbody>
    </motion.table>

    </>
    );
}
