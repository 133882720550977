import Styles from './styles/loading.module.scss';

function Loading() {
    return (
        <>
        <div className={Styles.loader}>
            <span></span>
        </div>      

        <p style={{fontSize:35, fontWeight:'600', marginTop:20}}>
            Loading please wait
        </p>

        </>
    )
    }

export default Loading

