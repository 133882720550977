import React, { useState, useEffect, forwardRef } from "react";
import {LineChart, Line, BarChart, Bar, Cell, XAxis, YAxis, Brush, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { API, graphqlOperation } from 'aws-amplify';

import ApplianceDailyData from '../components/ApplianceDailyData'
import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Styles from './styles/history.module.scss';

import { queryPowerUsed } from "../graphql/queries";

import Loading from '../components/Loading'

export default function Detail(props) {
    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    //TO KNOW WHICH CHART TO DISPLAY
    const [chartType, setChartType] = useState(1);

    //TO FIND OUT WHICH TYPE OF DATA HAS TO BE SHOWN
    const [totalPowerShow, setTotalPowerShow] = useState(true);
    const [totalCarbonShow, setTotalCarbonShow] = useState(false);
    const [totalCostShow, setTotalCostShow] = useState(false);

    //CHART DATA HOLDERS ----------
    //to know which button is selected (monthly weekly or daily)
    const [dataButtonIndex, setDataButtonIndex] = useState(2);

    //LOADING STATE
    const [isLoading, setIsLoading] = useState(true);

    //TO HOLD THE DATA FOR THE CHART
    const [data, setData] = useState(null);

    // THE DATE HOLDERS AND FUNCTIONS ----------
    //Remove 30 days from current date
    const removeDays = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() - 30);
        return result;
    }
    //add 30 days from current date
    const add60Days = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() + 60);
        return result;
    }
    //MAXIMUM OF A MONTH CAN BE PICKED
    const addDays = (date) =>{
        var result = new Date(date);
        result.setDate(result.getDate() + 30);
        return result;
    }
    // //CALCULATE THE NEXT DAY
    // const addOneDay = (date) =>{
    //     var result = new Date(date);
    //     result.setDate(result.getDate() + 1);
    //     return result;
    // }
    // //CALCULATE DATE FROM WEEK NUMBER
    // function getDateOfWeek(w, y) {
    //     var d = (1 + (w - 1) * 7); // 1st of January + 7 days for each week
    //     return new Date(y, 0, d);
    // }
    //CALCULATE WEEK FROM A DATE
    function getWeekfromDate(date){
        //inputDate to date
        var currentdate = new Date(date);
        //calculate jan 1st
        var oneJan = new Date(currentdate.getFullYear(),0,1);

        var numberOfDays = Math.floor((currentdate - oneJan) / (24 * 60 * 60 * 1000));
        var result = Math.ceil(( currentdate.getDay() + 1 + numberOfDays) / 7);
        return result
    }

    //EXPLORABLE DATES FUNCTION
    // const [exploredIndex, setExploredIndex] = useState(dataButtonIndex);

    async function exploreMoreButton (date) { 
        const selectedDate = new Date(date)
        setStartDate(selectedDate)
        setEndDate(addDays(selectedDate))

        switch(dataButtonIndex) {
            case 0:
                setDataButtonIndex(1)
                break;
            case 1:
                setDataButtonIndex(2)
                break;
            case 2: 
                setDataButtonIndex(3)
                break
            default:
                setDataButtonIndex(4)
        }
    }

    //DATE PICKER
    const [endDate, setEndDate] = useState(new Date());
    const [startDate, setStartDate] = useState(removeDays(endDate));

    const onChange = async (dates) => {
        const [start, end] = dates;
        setStartDate(start);
        setEndDate(end);
    };

    // INITIALLY LOAD THE DATA FOR THE CHART OF THE PAST 30 DAYS ---------------
    useEffect(() => {
        getData()
    }, [startDate, endDate, dataButtonIndex]);

    //FUNCTION TO LOAD DATA ON CLICK ---------------
    const getData = async () =>{
        setIsLoading(true)

        if(dataButtonIndex === 0){
            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'months', 
                        powerCalculationsRange: {end_date_time: endDate, start_date_time: startDate },
                    },
                )
            ).then((result)=>{
                var tempData = result.data.queryPowerUsed.preCalculations;
                tempData.forEach(element => {
                    element.timestamp = dateFormat(element.timestamp)
                });
                setData(tempData)
                setIsLoading(false)
                return
            }).catch((error)=>{
                console.log(error.errors[0].message)
                return
            });         

        }

        if(dataButtonIndex === 1){
            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'weeks', 
                        powerCalculationsRange: {end_date_time: addDays(endDate), start_date_time: startDate },
                    },
                )
            );            
            var tempData = result.data.queryPowerUsed.preCalculations;
            tempData.forEach(element => {
                element.timestamp = dateFormat(element.timestamp)
            });
            setData(tempData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 2){
            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'days', 
                        powerCalculationsRange: {end_date_time: endDate, start_date_time: startDate },
                    },
                )
            );            
            var tempData = result.data.queryPowerUsed.preCalculations;
            tempData.forEach(element => {
                element.timestamp = dateFormat(element.timestamp)
            });
            setData(tempData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 3) {

            var isoStartDate = new Date(startDate.setHours(0,0,0,0)).toISOString()
            var isoEndDate = new Date(startDate.setHours(23,0,0,0)).toISOString()

            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'hours', 
                        powerCalculationsRange: {start_date_time: isoStartDate, end_date_time: isoEndDate},
                    },
                )
            );            
            var tempData = result.data.queryPowerUsed.preCalculations;
            tempData.forEach(element => {
                element.timestamp = timeFormatHourly(element.timestamp)
            });
            setData(tempData)
            setIsLoading(false)
            return
        }

        if(dataButtonIndex === 4) {

            var isoStartDate = new Date(startDate.setHours(0,0,0,0)).toISOString()
            var isoEndDate = new Date(startDate.setHours(23,0,0,0)).toISOString()

            const result = await API.graphql(
                graphqlOperation(
                    queryPowerUsed, 
                    {
                        serial_number: serial,
                        powerCalculationType: 'TOTAL', 
                        intervalSize: 'quarterhours', 
                        powerCalculationsRange: {start_date_time: isoStartDate, end_date_time: isoEndDate},
                    },
                )
            );            
            var tempData = result.data.queryPowerUsed.preCalculations;
            tempData.forEach(element => {
                element.timestamp = timeFormat15Min(element.timestamp)
            });
            setData(tempData)
            setIsLoading(false)
            return
        }
    }
    //--------------------------------------------------------------------------

    //FUNTIONS TO HANDLE DATA HOLDER SWITCH
    const handleSwitch = (index) =>{
        setDataButtonIndex(index)
        if(index === 1){
            setEndDate(add60Days(startDate))
        }
        if(index === 2){
            setEndDate(addDays(startDate))
        }
        if(index === 3){
            setEndDate(addDays(startDate))
        }
        if(index === 4){
            setEndDate(addDays(startDate))
        }
        //load new data
    }

    //Clicking on the bars of the chart!
    const handleClick = (data, index) => {
        setActiveIndex(index)
    };
    //Which bar is active
    const [activeIndex, setActiveIndex] = useState(0);
    //Custom tooltip (WHEN HOVERING ON THE BARS)
    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
        return (
            <div style={{backgroundColor:'rgba(255, 255, 255,.9)', padding:10, borderRadius:15, }}>
                <p style={{fontWeight:'bold', margin:0, padding:0, }}>{`${label}`}</p>
                <div>
                    {payload[0].dataKey === 'total_kwh' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh): `}</p>
                    :
                    payload[0].dataKey === "total_carbon" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e): `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP): `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0].value}\n`}</p>
                </div>

                {payload[1]? 
                <div>
                    {payload[1].dataKey === 'total_kwh' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh): `}</p>
                    :
                    payload[1].dataKey === "total_carbon" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e): `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP): `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[1].value}\n`}</p>
                </div>

                :
                <></>
                }

                {payload[2]? 
                <div>
                    {payload[2].dataKey === 'total_kwh' ? 
                    <p style={{display:'inline'}}>{`Total power used (KWh): `}</p>
                    :
                    payload[2].dataKey === "total_carbon" ?
                    <p style={{display:'inline'}}>{`Total carbon (kg CO2e): `}</p>
                    :
                    <p style={{display:'inline'}}>{`Total cost (GBP): `}</p>
                    }
                    <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[2].value}\n`}</p>
                </div>
                :
                <></>
                }

            </div>
        );
        }
        return null;
    };

    function dateFormat(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        var month = parseInt(dateInit.getUTCMonth()) + 1
        var formattedDate = dateInit.getDate() + '/' + month + '/' + dateInit.getFullYear()
        return formattedDate
    }

    function timeFormatHourly(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        
        var oneHour = 3600
        var oneHourLater =  new Date(0);
        oneHourLater.setSeconds(timestamp - oneHour);

        var formattedDate = dateInit.toLocaleTimeString()
        var formattedHourLater = oneHourLater.toLocaleTimeString()

        var result = formattedHourLater + ' to ' + formattedDate

        return result
    }

    function timeFormat15Min(timestamp){
        var dateInit = new Date(0);
        dateInit.setSeconds(timestamp);
        
        var oneHour = 900
        var oneHourLater =  new Date(0);
        oneHourLater.setSeconds(timestamp - oneHour);

        var formattedDate = dateInit.toLocaleTimeString()
        var formattedHourLater = oneHourLater.toLocaleTimeString()

        var result = formattedHourLater + ' to ' + formattedDate

        return result
    }

    //Function to create the graph
    function createGraph(){
        const activeItem = data[activeIndex];
        return (
            <div className='graphdiv2'>
                <div style={{ width: '100%' }}>
                    <ResponsiveContainer width="100%" height={500}>
                    {chartType === 0 ? 
                        <LineChart
                            width={150} 
                            height={350} 
                            data={data}           
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}
                            >
                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey="timestamp" />
                            
                            <YAxis yAxisId="left"  label={{ value: "Power", angle: -90, position: 'insideLeft', }} stroke="#34485b" />
                            <YAxis yAxisId="right" dataKey='total_carbon' orientation="right" label={{ value: 'Carbon', angle: 90, position: 'insideRight' }} stroke="#3d6955" />

                            <Tooltip content={<CustomTooltip/>} />

                            <Legend wrapperStyle={{bottom:-10, marginBottom:10}} />
                            
                            {totalPowerShow === true ? 
                            <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey="total_kwh" stroke="#34485b" isAnimationActive={true}/>
                            :
                            <></>
                            }
                        
                            {totalCostShow === true ? 
                            <Line onClick={handleClick} yAxisId="left" type="monotone" strokeWidth='3' dataKey="total_cost" stroke="#b85a68" isAnimationActive={true}/>
                            :
                            <></>
                            }

                            {totalCarbonShow === true ? 
                            <Line yAxisId="left" type="monotone" strokeWidth='3' dataKey='total_carbon' stroke="#3d6955" isAnimationActive={true}/>
                            :
                            <></>
                            }
                            
                            <Brush height={20} dataKey="Date" stroke="#34485b"  />

                        </LineChart>
                        :
                        <BarChart 
                            width={150} 
                            height={350} 
                            data={data}           
                            margin={{
                                top: 30,
                                right: 30,
                                left: 30,
                                bottom: 5,
                            }}>
                            
                            <Tooltip content={<CustomTooltip/>} />

                            <CartesianGrid strokeDasharray="3 3" />

                            <XAxis dataKey={'timestamp'}/>
                            
                            {totalPowerShow === true ? 
                            <Bar isAnimationActive={true} dataKey="total_kwh" onClick={handleClick} >
                            {data.map((entry, index) => (
                                <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]} stroke="0" cursor="pointer" fill={'#34485b'} key={`cell-${index}`} />
                            ))}
                            </Bar>
                            :
                            <></>
                            }

                            {totalCostShow === true ? 
                            <Bar isAnimationActive={true} dataKey="total_cost" yAxisId="right" onClick={handleClick} >
                            {data.map((entry, index) => (
                                <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#b85a68'} key={`cell-${index}`} />
                            ))}
                            </Bar>
                            :
                            <></>
                            }

                            {totalCarbonShow === true ? 
                            <Bar isAnimationActive={true} dataKey="total_carbon" yAxisId="right" onClick={handleClick} >
                            {data.map((entry, index) => (
                                <Cell style={{filter: index === activeIndex && `drop-shadow(0px 0px 5px #319e9b`}} radius={[5, 5, 0, 0]}  cursor="pointer" fill={'#3d6955'} key={`cell-${index}`} />
                            ))}
                            </Bar>
                            :
                            <></>
                            }

                            <Brush height={20} dataKey="Date" stroke="#34485b"  />

                        </BarChart>
                        }
                    </ResponsiveContainer>

                    <div className={Styles.seperator} />

                    <div style={{ marginTop:20, marginBottom:20, width:'100%', textAlign:'center'}}>
                        <button onClick={()=> totalCostShow === false && totalCarbonShow === false && totalPowerShow === true ? setTotalPowerShow(totalPowerShow) : setTotalPowerShow(!totalPowerShow)} className={ totalPowerShow === false ? Styles.selectButton : Styles.selectButtonSelected} style={totalPowerShow === true ? {backgroundColor:'#34485b', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Power
                        </button>
                        <button onClick={()=> totalPowerShow === false && totalCarbonShow === false && totalCostShow === true ? setTotalCostShow(totalCostShow) : setTotalCostShow(!totalCostShow)} className={ totalCostShow === false ? Styles.selectButton : Styles.selectButtonSelected}  style={totalCostShow === true ? {backgroundColor:'#b85a68', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Costs
                        </button>
                        <button onClick={()=> totalCostShow === false && totalPowerShow === false && totalCarbonShow === true ? setTotalCarbonShow(totalCarbonShow) : setTotalCarbonShow(!totalCarbonShow)} className={ totalCarbonShow === false ? Styles.selectButton : Styles.selectButtonSelected} style={totalCarbonShow === true ? {backgroundColor:'#3d6955', color:'#EEF0F4'}: {backgroundColor:'#EEF0F4'} }>
                            Carbon
                        </button>
                    </div>
                    <div className={Styles.seperator} />

                    <div style={{ marginTop:20, marginBottom:20, width:'100%', textAlign:'center'}}>
                        {chartType === 1 && dataButtonIndex !== 4 ? 
                        <button onClick={()=>exploreMoreButton(activeItem?.start_time)} className={Styles.exploreMore}>
                            Explore this date
                        </button>
                        :
                        <></>
                        }
                        <button onClick={()=> {chartType === 0 ? setChartType(1) : setChartType(0)}} className={Styles.exploreMore}>
                            {chartType === 0 ? 'Bar Chart' : 'Line Chart'}
                        </button>
                    </div>

                    <div className={Styles.seperator} />

                    {data.length > 0 ?
                        dataButtonIndex === 1 && activeItem?.start_time ?
                        <>
                            <p className={Styles.usageTitle}>
                                {`The week ${getWeekfromDate(activeItem?.start_time)}`}
                            </p>

                            <div className={Styles.seperator} />

                            <div className={Styles.infoBox}>
                                <p className={Styles.titleText}>
                                    Total Power Used
                                </p>
                                <p className={Styles.infoText}>
                                    {`${activeItem.total_kwh.toFixed(2)} (KWh)`}
                                </p>
                            </div>
        
                            <div className={Styles.infoBox}>
                                <p className={Styles.titleText}>
                                    Total Costs
                                </p>
                                <p className={Styles.infoText}>
                                    {activeItem.total_cost === null ? 
                                        'Setup tariff'
                                    :
                                        `${activeItem.total_cost.toFixed(2)} (GBP)`
                                    }
                                </p>
                            </div>
        
                            <div className={Styles.infoBox}>
                                <p className={Styles.titleText}>
                                    Total Carbon
                                </p>
                                <p className={Styles.infoText}>
                                    {`${activeItem.total_carbon.toFixed(2)} (kg CO2e)`}
                                </p>
                            </div>
                        </>
                        :
                            activeItem?
                            <>
                                <p className={Styles.usageTitle}>
                                    {/* {`${activeItem.start_time}`} */}
                                    {activeItem.timestamp}
                                    {/* {`${ new Date(activeItem.timestamp).getDate() + '/' + new Date(activeItem.timestamp).getMonth() + '/' + new Date(activeItem.timestamp).getFullYear()}`} */}
                                </p>
            
                                <div className={Styles.seperator} />
            
                                <div className={Styles.infoBox}>
                                    <p className={Styles.titleText}>
                                        Total Power Used
                                    </p>
                                    <p className={Styles.infoText}>
                                        {`${activeItem.total_kwh.toFixed(2)} (KWh)`}
                                    </p>
                                </div>
            
                                <div className={Styles.infoBox}>
                                    <p className={Styles.titleText}>
                                        Total Costs
                                    </p>
                                    <p className={Styles.infoText}>
                                        {activeItem.total_cost === null ? 
                                            'Setup tariff'
                                        :
                                            `${activeItem.total_cost.toFixed(2)} (GBP)`
                                        }
                                    </p>
                                </div>
            
                                <div className={Styles.infoBox}>
                                    <p className={Styles.titleText}>
                                        Total Carbon
                                    </p>
                                    <p className={Styles.infoText}>
                                        {`${activeItem.total_carbon.toFixed(2)} (kg CO2e)`}
                                    </p>
                                </div>
                            </>
                            :
                            <></>
                        :
                        <></>
                    }
                    
                </div>
        </div>
        
        )
    }

    //CUSTOM INPUT BUTTON
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={Styles.goBackButton} style={{width:250}} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));
    
    return (
        <>
            <PageTitle title='History'/>
            {/*------------------------------ HEADER ------------------------------*/}
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'History'}/>

            {/*------------------------------ TODAYS USAGE ------------------------------*/}
            <ApplianceDailyData serial={serial}/>


        <div className={Styles.dataSelectorDiv}>
            {/* --------- *** THE DATA HOLDER SWITCH BUTTONS *** --------- */}
            <p style={{ fontSize:'1.5em', fontWeight:'600', }}>Please select how would you like to see your appliance data</p>

            <div className={Styles.seperator} />

            <div className={Styles.header} style={{ marginBottom:10}}>
                <button className={[dataButtonIndex === 0 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(0)} >
                    Monthly
                </button>

                <button className={[dataButtonIndex === 1 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(1)}>
                    Weekly
                </button>

                <button className={[dataButtonIndex === 2 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(2)}>
                    Daily
                </button>

                <button className={[dataButtonIndex === 3 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(3)}>
                    Hourly
                </button>

                <button className={[dataButtonIndex === 4 ? Styles.buttonSelected : Styles.goBackButton]} onClick={() => handleSwitch(4)}>
                    15 Minutes
                </button>
            </div>

            <div className={Styles.seperator} />

            {/* --------- *** THE TIME PICKER *** --------- */}
            <p style={{ fontSize:'1.5em', fontWeight:'600',  }}>Please select the dates you would like to see your data</p>
            
            <div className={Styles.seperator} />

            <div className={Styles.header} >
                {dataButtonIndex === 0 ? 

                <DatePicker 
                showPopperArrow={false}

                selected={startDate}
                onChange={onChange}

                dateFormat="d/MM/yyyy"

                startDate={startDate}
                endDate={endDate}

                selectsRange
                showMonthYearPicker

                calendarClassName={Styles.datePicker}
                popperClassName={Styles.datePickerPopper}

                customInput={<DatePickerCustomInput/>}
                />
                : dataButtonIndex === 1 ? 
                <>

                <DatePicker 
                    showPopperArrow={false}

                    selected={startDate}
                    onChange={onChange}

                    dateFormat="d/MM/yyyy"

                    startDate={startDate}
                    endDate={endDate}
                    maxDate={add60Days(startDate)}

                    selectsRange
                    showMonthYearPicker

                    calendarClassName={Styles.datePicker}
                    popperClassName={Styles.datePickerPopper}
                    showWeekNumbers
                    customInput={<DatePickerCustomInput/>}
                />
</>
                : dataButtonIndex === 2 ? 

                <DatePicker 
                    showPopperArrow={false}
                    dateFormat="d/MM/yyyy"

                    selected={startDate}
                    onChange={onChange}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    maxDate={addDays(startDate)}

                    calendarClassName={Styles.datePicker}
                    popperClassName={Styles.datePickerPopper}

                    customInput={<DatePickerCustomInput/>}
                />

                :

                <DatePicker 
                    showPopperArrow={false}
                    dateFormat="d/MM/yyyy"

                    selected={startDate}
                    onChange={setStartDate}
                    startDate={startDate}

                    calendarClassName={Styles.datePicker}
                    popperClassName={Styles.datePickerPopper}

                    customInput={<DatePickerCustomInput/>}
                />
                }

            </div>

            <div className={Styles.seperator} />

            {
            isLoading === true ?
                <div className={Styles.loader}>
                    <span></span>
                </div> 
            :
                data ?
                    data.length === 0 ? 
                        <p className={Styles.usageTitle} style={{marginBottom:10}}>
                            No Data Available
                        </p>
                    :
                        <>
                            {createGraph()}
                        </>
                : 
                    <Loading/> 
            }
        </div> 
        </>
    );
}

