import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Amplify from 'aws-amplify';


const myAppConfig = {
    "aws_project_region": process.env.REGION || process.env.REACT_APP_REGION,
    "aws_cognito_region": process.env.REGION || process.env.REACT_APP_REGION,
    "aws_cognito_identity_pool_id": process.env.AMPLIFY_IDENTITYPOOL_ID || process.env.REACT_APP_IDENTITYPOOL_ID,
    "aws_user_pools_id": process.env.AMPLIFY_USERPOOL_ID || process.env.REACT_APP_USERPOOL_ID,
    "aws_user_pools_web_client_id": process.env.AMPLIFY_WEBCLIENT_ID || process.env.REACT_APP_WEBCLIENT_ID,
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": process.env.AMPLIFY_GRAPHQL_ENDPOINT || process.env.REACT_APP_GRAPHQL_ENDPOINT,
    "aws_appsync_region": process.env.REGION || process.env.REACT_APP_REGION
}

Amplify.configure(myAppConfig);

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
