import React, { useState, useEffect } from "react";

import { useLocation } from "react-router-dom"
import { API, graphqlOperation } from 'aws-amplify';
import Loading from '../components/Loading'
import Styles from './styles/info.module.scss';
import {queryVervConnects} from '../graphql/queries';
import {addOnboarding} from '../graphql/mutations';

import ApplianceDailyData from '../components/ApplianceDailyData'
import ApplianceConnectionData from '../components/ApplianceConnectionData'
import NavigationButtons from '../components/NavigationButtons'
import PageTitle from '../components/PageTitle'

import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';
import "react-datepicker/dist/react-datepicker.css";

dayjs.extend(calendar);

export default function Detail(props) {

    const pathArray = window.location.pathname.split('/');
    const serial = pathArray[2];

    const [data, setData] = useState(null);

    const location = useLocation();

    //DIFFERENT EDIT STATES
    const [editInfo, setEditInfo] = useState(false);

    //APPLIANE INFORMATION 
    //STORE INPUTS IN STATES FOR EDITING SECTION
    const [age, onAgeChange] = React.useState('');
    const [brand, onBrandChange] = React.useState('');
    const [brandManual, onBrandManualChange] = React.useState(brand);

    const [business, onBusinessChange] = React.useState(true);
    const [home, onHomeChange] = React.useState(!business);
    const [business_name, onBusinessNameChange] = React.useState('');

    const [floor, onFloorChange] = React.useState('');
    const [deviceLocation, onDeviceLocation] = React.useState('');

    const [model, onModelChange] = React.useState('');
    const [nickname, onNicknameChange] = React.useState('');
    
    const [price, onPriceChange] = React.useState('');
    const [site, onSite] = React.useState('');
    const [applianceType, onApplianceType] = React.useState('');
    const [applianceTypeManual, onApplianceTypeManual] = React.useState(applianceType);
    const [deviceType, onDeviceType] = React.useState('');

    const [group, setGroup] = React.useState('');


    //GET APPLIANCE DATA
    useEffect(() => {
        (async () => {
            await API.graphql(
                graphqlOperation(
                    queryVervConnects,
                    {
                        serial_number: serial 
                    }
                )
            ).then((result)=>{
                let element = result.data.queryVervConnects.items[0];
                setData(element)

                onAgeChange(element.appliance.age)
                onBrandChange(element.appliance.brand)
    
                onBusinessChange(element.appliance.business)
                onBusinessNameChange(element.appliance.business_name)
    
                onFloorChange(element.appliance.floor)
                onDeviceLocation(element.appliance.location)
                onSite(element.appliance.site)
    
                onModelChange(element.appliance.model)
                onNicknameChange(element.appliance.nickname)
    
                onPriceChange(element.appliance.price_paid)
                onApplianceType(element.appliance.type)
    
                onDeviceType(element.device_type)

                setGroup(element.group)
            }).catch((error)=>{
                console.log(error)
                return
            })
        })();
    }, []);

    // ****************************** FUNCTION TO SAVE DATA TO AWS ******************************
    const [error, setError] = useState('');
    async function saveData(){
        if(nickname === '' || nickname === undefined || applianceType === '' || applianceType === undefined || brand === '' || brand === undefined || model === '' || model === undefined || site === '' || site === undefined || location === '' || location === undefined ){
            setError('Please check all the information and make sure you have the correct data.')
            return
        }

        if(applianceType === 'other' && applianceTypeManual === ''){
            setError('Please enter the appliance type.')
            return
        }
        if(brand === 'other' && brandManual === ''){
            setError('Please enter the appliance type.')
            return
        }

        let typeAppliance
        if(applianceType === 'other'){
            typeAppliance = applianceTypeManual
        }else{
            typeAppliance = applianceType
        }

        let brandAppliance
        if(brand === 'other'){
            brandAppliance = brandManual
        } else{
            brandAppliance = brand
        }

        var appliance = {
            age:age,

            business:business,
            business_name:business_name,

            floor:floor,
            location:deviceLocation,

            model:model,
            nickname:nickname,
            
            price_paid: price,
            site:site,

            type: typeAppliance,
            brand:brandAppliance,
        }

        const writable = {
            onboarding_type: 'edit', 
            serial_number: serial, 
            appliance: appliance,
            group: group,
        }


        try {
            const addPlug = await API.graphql({
                query: addOnboarding, 
                variables: writable
            })
        }catch (err) {
            console.log(err)
            setError('Oops! Something went wrong! ' + err.errors[0].message)
            return
        }
        setError('')
        setEditInfo(false)
    }

    return (
        <>
        {data ?
        <> 
            <PageTitle title='More Information and Settings'/>
            {/*------------------------------ HEADER ------------------------------*/}
            {/*------------------------------ NAVIGATION ------------------------------*/}
            <NavigationButtons serial={serial} currentPage={'MoreInfo'}/>

            {/*------------------------------ TODAYS USAGE ------------------------------*/}
            <ApplianceDailyData serial={serial} type={applianceType}/>

            {/* -------------- ALL INFORMATION ABOUT THE PLUG -------------- */}
            <ApplianceConnectionData serial={serial}/>

        <div className={Styles.mainContainer}>
            {editInfo === false ? 
            <>
                {/* --------------------- APPLIANCE INFORMATION --------------------- */}
                <p className={Styles.usageTitle}>
                    Appliance Information
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Nickname
                    </p>
                    <p className={Styles.infoText}>
                        {nickname}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Appliance Type
                    </p>
                    <p className={Styles.infoText}>
                        {applianceType}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Brand
                    </p>
                    <p className={Styles.infoText}>
                        {brand}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Model
                    </p>
                    <p className={Styles.infoText}>
                        {model}
                    </p>
                </div>
                <div className={Styles.seperator} />

                {/* --------------------- LOCATION INFORMATION --------------------- */}

                <p className={Styles.usageTitle}>
                    Appliance Location 
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Site
                    </p>
                    <p className={Styles.infoText}>
                        {site}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Location
                    </p>
                    <p className={Styles.infoText}>
                        {deviceLocation}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Floor
                    </p>
                    <p className={Styles.infoText}>
                        {floor}
                    </p>
                </div>

                {business === true ? 
                    <div className={Styles.infoBox4}>
                        <p className={Styles.titleText}>
                            Company
                        </p>
                        <p className={Styles.infoText}>
                            {business_name}
                        </p>
                    </div>
                :<></>}

                <div className={Styles.seperator} />
                {/* --------------------- OTHER INFORMATION --------------------- */}

                <p className={Styles.usageTitle}>
                    Other Information 
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Age
                    </p>
                    <p className={Styles.infoText}>
                        {age} Months
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Purchase Price
                    </p>
                    <p className={Styles.infoText}>
                        £{price}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Utilisation Count
                    </p>
                    <p className={Styles.infoText}>
                        {data.utilisation_count}
                    </p>
                </div>

                <div className={Styles.infoBox4}>
                    <p className={Styles.titleText}>
                        Device Type
                    </p>
                    <p className={Styles.infoText}>
                        {deviceType}
                    </p>
                </div>

                <div className={Styles.seperator} />

                <button className={Styles.editButton} onClick={()=>setEditInfo(true)}>Edit</button>
            </>
            :
            <>
            {/* --------------------- *** EDIT SECTION *** --------------------- */}
                <p className={Styles.usageTitleEdit}>
                    Edit Device Information
                </p>
                <div className={Styles.seperator} />
                <p className={Styles.usageTitle}>
                    Appliance Information 
                </p>
                
                <div className={Styles.seperator} />

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Nickname
                    </p>
                    <input className={Styles.inputTH} value={nickname} onChange={(e) => onNicknameChange(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Required)
                    </p>
                </div>

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Appliance Type
                    </p>
                    <label className={Styles.labelTarrif} >
                        <select onChange={(e) => onApplianceType(e.target.value)}>
                            <option  key={'Select'} value={'0'}>Select</option> 
                            <option  key={'Air Condition'} value={'Air Condition'}>Air Condition</option> 
                            <option  key={'Washing Machine'} value={'Washing Machine'}>Washing Machine</option> 
                            <option  key={'Boiler'} value={'Boiler'}>Boiler</option> 
                            <option  key={'Fan'} value={'Fan'}>Fan</option> 
                            <option  key={'Coffee Machine'} value={'Coffee Machine'}>Coffee Machine</option> 
                            <option  key={'Refrigerator'} value={'Refrigerator'}>Refrigerator</option> 
                            <option  key={'Other'} value={'other'}>Other</option> 
                        </select>
                    </label>
                    <p className={Styles.titleTextEdit}>
                        (Required)
                    </p>
                </div>
                {applianceType === 'other' &&
                    <div className={Styles.infoBoxEdit}>
                        <p className={Styles.titleTextEdit}>
                            Appliance Type
                        </p>
                        <input className={Styles.inputTH} value={applianceTypeManual} onChange={(e) => onApplianceTypeManual(e.target.value)} name="fname"/>
                        <p className={Styles.titleTextEdit}>
                            (Required)
                        </p>
                    </div>
                }

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Brand
                    </p>
                    <label className={Styles.labelTarrif} >
                        <select onChange={(e) => onBrandChange(e.target.value)}>
                            <option  key={'Select'} value={'0'}>Select</option> 
                            <option  key={'Carrier'} value={'Carrier'}>Carrier</option> 
                            <option  key={'Daikin'} value={'Daikin'}>Daikin</option> 
                            <option  key={'LG'} value={'LG'}>LG</option> 
                            <option  key={'Mitsubishi'} value={'Mitsubishi'}>Mitsubishi</option> 
                            <option  key={'Samsung'} value={'Samsung'}>Samsung</option> 
                            <option  key={'Other'} value={'other'}>Other</option> 
                        </select>
                    </label>
                    <p className={Styles.titleTextEdit}>
                        (Required)
                    </p>
                </div>

                {brand === 'other' &&
                    <div className={Styles.infoBoxEdit}>
                        <p className={Styles.titleTextEdit}>
                            Brand
                        </p>
                        <input className={Styles.inputTH} value={brandManual} onChange={(e) => onBrandManualChange(e.target.value)} name="fname"/>
                        <p className={Styles.titleTextEdit}>
                            (Required)
                        </p>
                    </div>
                }

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Model
                    </p>
                    <input className={Styles.inputTH} value={model} onChange={(e) => onModelChange(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Required)
                    </p>
                </div>

                <div className={Styles.seperator} />

                <p className={Styles.usageTitle}>
                    Appliance Location 
                </p>
                
                <div className={Styles.seperator} />

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Site
                    </p>
                    <input className={Styles.inputTH} value={site} onChange={(e) => onSite(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Optional)
                    </p>
                </div>

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Location
                    </p>
                    <input className={Styles.inputTH} value={deviceLocation} onChange={(e) => onDeviceLocation(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Optional)
                    </p>
                </div>

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Floor
                    </p>
                    <input className={Styles.inputTH} value={floor} onChange={(e) => onFloorChange(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Optional)
                    </p>
                </div>

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Owner Type
                    </p>

                    <button 
                        className={business === true ? Styles.selectedButton : Styles.notSelectedButton} 
                        onClick={()=>[onBusinessChange(true), onHomeChange(false)]}>
                            Business
                    </button>  

                    <button 
                        className={home === true ? Styles.selectedButton : Styles.notSelectedButton} 
                        onClick={()=>[onBusinessChange(false), onHomeChange(true)]}>
                            Home
                    </button>   
                    <p className={Styles.titleTextEdit}>
                        (Required)
                    </p>           
                </div>

                {business === true ? 
                    <div className={Styles.infoBoxEdit}>
                        <p className={Styles.titleTextEdit}>
                            Business Name
                        </p>
                        <input className={Styles.inputTH} value={business_name} onChange={(e) => onBusinessNameChange(e.target.value)} name="fname"/>
                        <p className={Styles.titleTextEdit}>
                            (Required)
                        </p>
                    </div>
                :
                    <></>
                }

                <div className={Styles.seperator} />
                
                <p className={Styles.usageTitle}>
                    Other Information 
                </p>
                
                <div className={Styles.seperator} />

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Age (Months)
                    </p>
                    <input type="number" className={Styles.inputTH} value={age} onChange={(e) => onAgeChange(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Optional)
                    </p>
                </div>

                <div className={Styles.infoBoxEdit}>
                    <p className={Styles.titleTextEdit}>
                        Purchase Price (£)
                    </p>
                    <input type="number" className={Styles.inputTH} value={price} onChange={(e) => onPriceChange(e.target.value)} name="fname"/>
                    <p className={Styles.titleTextEdit}>
                        (Optional)
                    </p>
                </div>

                <div className={Styles.seperator} />
                {error !== '' ? 
                    <>
                        <p style={{ fontSize:24, fontWeight:'600', color:'#FF5E15'}}>
                            {error}
                        </p>

                        <div className={Styles.seperator} />
                    </>
                :
                    <></>
                }

                <button className={Styles.ButtonCancel} onClick={()=>setEditInfo(false)} >
                    Cancel
                </button>

                <button className={Styles.ButtonSave} onClick={()=>[saveData()]} >
                    Save
                </button> 
            </>
            }
        </div>

        {/* -------------- EFFICIENCY NOTIFICATION SETUP -------------- */}
        {/* <div className={Styles.mainContainer}>
            <div className={Styles.infoBoxThreshold}>
                <p className={Styles.titleText}>
                    Current Threshold
                </p>
                {editThreshold === false ? 
                <p className={Styles.infoText}>
                    {thresholdValue + ' KWh'}
                </p>
                :
                <input type="number" min="0" className={Styles.inputTH} value={tempThresholdValue} onChange={(e) => setTempThresholdValue(e.target.value)} name="fname"/>
                }
            </div>

            <div className={Styles.infoBoxThreshold}>
                <p className={Styles.titleText}>
                    Efficiency Margin
                </p>

                {editThreshold === false ? 
                <p className={Styles.infoText}>
                    10%
                </p>
                :
                <label className={Styles.labelTarrif} >
                    <select>
                        <option  key={'Select'} value={'0'}>Select</option> 
                        <option  key={'5%'} value={'5%'}>5%</option> 
                        <option  key={'10%'} value={'5%'}>10%</option> 
                        <option  key={'15%'} value={'15%'}>15%</option> 
                    </select>
                </label>
                }
            </div>

            <div className={Styles.seperator} />
            {editThreshold === false ? 
            <button className={Styles.editButton} onClick={()=>setEditThreshold(true)}>Edit</button>
            :
            <>
                <button className={Styles.ButtonCancel} onClick={()=>setEditThreshold(false)} >
                    Cancel
                </button>
                <button className={Styles.ButtonSave} onClick={()=>[setEditThreshold(false), setThresholdValue(tempThresholdValue)]} >
                    Save
                </button>            
            </>
            }
        </div> */}

        </> : 
            <Loading/>

        }

        </>
    );
}