import React, { useEffect, useState } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Graph = ({fields, data}) =>  {
  
    const [arr,setArr] = useState([]);

    const [field, setField] = useState('RealPower');

    const [fieldLabel, setFieldLabel] = useState('Real Power W');

    const validate = (value1, value2, value3) => {
        setArr((prevState)=>{
            if(prevState.length > 60){
                prevState.splice(0,prevState.length-60);
            }

            var nameOfPhase1 = field + ' L1'
            var nameOfPhase2 = field + ' L2'
            var nameOfPhase3 = field + ' L3'

            const newValue = { Name: (new Date().toISOString().substr(11, 8))};
            newValue[nameOfPhase1] = value1;
            newValue[nameOfPhase2] = value2;
            newValue[nameOfPhase3] = value3;

            console.log(newValue)

            handleLabel(field)
            return( [...prevState,newValue]);
        });
    }

    useEffect(() => {
        console.log(data)
        var nameOfPhaseField1 = field + ' L1'
        var nameOfPhaseField2 = field + ' L2'
        var nameOfPhaseField3 = field + ' L3'

        validate(data[0]?.[nameOfPhaseField1], parseFloat(data[0]?.[nameOfPhaseField2]), parseFloat(data[0]?.[nameOfPhaseField3]));
    },[data]);

    
    const handleSelect = (eve) => {
      if(eve.target.value == 'Voltage'){
        setField('Volts')
      }
      setField(eve.target.value);
    }

    const handleLabel = (field) => {
      if(field === 'RealPower'){
        setFieldLabel('Real Power (W)')
        return
      }

      if(field === 'ApparentPower'){
        setFieldLabel('Apparent Power (VA)')
        return
      }

      if(field === 'Current'){
        setFieldLabel('Current (A)')
        return
      }

      if(field === 'PowerFactor'){
        setFieldLabel('Power Factor (W)')
        return
      }

      if(field === 'Voltage'){
        setFieldLabel('Voltage (V)')
        return
      }

    }

  //Custom tooltip (WHEN HOVERING ON THE BARS)
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
    return (
        <div style={{backgroundColor:'rgba(255, 255, 255,.9)', padding:10, borderRadius:15, }}>

            <p style={{fontWeight:'bold', margin:0, padding:0, }}>{`${label}`}</p>

            {payload.length === 1 ? 
            <div>
                <p style={{display:'inline', color: payload[0]?.color}}>{`${payload[0]?.name}: `}</p>
                <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0]?.value}\n`}</p>
            </div>

            : payload.length !== 1 ?
            <>
            <div>
                <p style={{display:'inline', color: payload[0]?.color}}>{`${payload[0]?.name}: `}</p>
                <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[0]?.value}\n`}</p>
            </div>

            <div>
                <p style={{display:'inline', color: payload[1]?.color}}>{`${payload[1]?.name}: `}</p>
                <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[1]?.value}\n`}</p>
            </div>

            <div>
                <p style={{display:'inline', color: payload[2]?.color}}>{`${payload[2]?.name}: `}</p>
                <p style={{fontWeight:'bold', display:'inline'}}>{`${payload[2]?.value}\n`}</p>
            </div>
            </>
            :
            <></>
          }
        </div>
    );
    }
    return null;
  };
  
  return (
      <>

      <div className='graphView'>
        <div className="filter">
              <label>
                  Filter by field

                  <select id="browsers" onChange={handleSelect} value={field}>
                      {fields.map((value, index) => <option  key={value.accessor} value={value.accessor}>{value.Header}</option>)}
                  </select>

              </label>
          </div>

        <ResponsiveContainer width="100%" height={600}>

          <LineChart
              width={960}
              height={500}
              data={arr}
              margin={{
                top: 50,
                right: 10,
                left: 10,
                bottom: 20
              }}
              >
              <CartesianGrid strokeDasharray="3 3" />

              <XAxis dataKey="Name" stroke='#34485b' />

              <YAxis yAxisId="left"  label={{ value: fieldLabel, angle: -90, position: 'insideLeft', }} stroke="#34485b" />

              <Tooltip content={<CustomTooltip/>} />

              <Legend wrapperStyle={{bottom:-10, marginBottom:10}} />

              <Line yAxisId="left" type="monotone" strokeWidth='3' dataKey={field + ' L1'} stroke="#319e9b" isAnimationActive={true}/>
              <Line yAxisId="left" type="monotone" strokeWidth='3' dataKey={field + ' L2'} stroke="#b85a68" isAnimationActive={true}/>
              <Line yAxisId="left" type="monotone" strokeWidth='3' dataKey={field + ' L3'} stroke="#3d6955" isAnimationActive={true}/>

          </LineChart>
        </ResponsiveContainer>
        </div>
        </>
    );
}

export default Graph;

