import React, { useState, useEffect } from "react";
import { API } from 'aws-amplify';
import Styles from './styles/applianceDailyData.module.scss';

import {queryVervConnects} from '../graphql/queries';

import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';
import "react-datepicker/dist/react-datepicker.css";

dayjs.extend(calendar);

function MomentDate(value) {
    const isValid = dayjs(value).isValid()
    return (
        isValid ? dayjs(value).calendar() : '–'
    )
}

export default function Detail(props) {
    // LOAD INFORMATION ABOUT THE PLUG -------------
    const [data, setData] = useState(null);
    const [serial, setSerial] = useState(props.serial);

    useEffect(() => {
        (async () => {
            const result = await API.graphql({ query: queryVervConnects });
            const allData = result.data.queryVervConnects.items;
            allData.forEach(element => {
                if(element.serial === serial){
                    setData(element)
                }
            });
        })();
    }, []);

    return (
            <div className={Styles.usageContainer}>

                <p className={Styles.usageTitle}>
                    Device Connection
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Last Used
                    </p>
                    <p className={Styles.infoText}>
                        {MomentDate(data?.last_used)}
                    </p>
                </div>

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Last Connection
                    </p>
                    <p className={Styles.infoText}>
                        {MomentDate(data?.last_connection)}
                    </p>
                </div>

                <div className={Styles.infoBox}>
                    <p className={Styles.titleText}>
                        Last High Speed Data
                    </p>
                    <p className={Styles.infoText}>
                        {MomentDate(data?.last_s3_item)}
                    </p>
                </div>
            </div>
    )

}


