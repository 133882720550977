
function NoVervConnectsMessage() {
    return (
        <>

        <p style={{fontSize:35, fontWeight:'600', marginTop:20}}>
            You have no Verv Connects registered to your account. You can use the Verv Connect
            mobile app available on Apple and Android stores, or contact <a href="mailto:support@verv.energy">Verv Support</a>.
        </p>

        </>
    )
}

export default NoVervConnectsMessage
