import React, { useState, useEffect, forwardRef, useRef } from "react";
import Styles from './styles/tarrifs.module.scss';

import { API, graphqlOperation } from 'aws-amplify';
import { queryEnergySuppliers, queryEnergyProducts } from '../graphql/queries';
import { addEnergyProduct, updateEnergyProduct } from '../graphql/mutations';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import PageTitle from '../components/PageTitle'
import Loading from '../components/Loading'

export default function Tarrifs(props) {
    //THE INPUT PLACEHOLDERS
    const [price, setPrice] = useState(0);
    const [standingPrice, setStandingPrice] = useState(0);
    const [offPeakPrice, setOffPeakPrice] = useState(0);
    const [peakPrice, setPeakPrice] = useState(0);
    const [companyNumber, setCompanyNumber] = useState('0');

    //THE PLACEHOLDER FOR PEAK TIME SELECTION
    const [startTime, setStartTime] = useState(new Date().setHours(0,0,0,0));
    const [endTime, setEndTime] = useState(new Date().setHours(0,0,0,0));

    //THE ERROR HANDLE 
    const [error, setError] = useState('');

    //THE PLACEHOLDER FOR THE RADIO BUTTONS 
    const [energyPriceTypeConstant, setEnergyPriceTypeConstant] = useState(false);
    const [energyPriceTypeVariable, setEnergyPriceTypeVariable] = useState(false);


    //THE EDIT MODE STATE
    const [editMode, setEditMode] = useState(false);
    const editRef = useRef(null)

    //GO TO EDIT SECTION UPON CLICK
    async function openEdit(){
        await setEditMode(true)
        if(editRef.current != null){
            editRef.current.scrollIntoView({ behavior: "smooth", block: "start", inline: "nearest" })
        }
    }

    // LOAD THE DATA OF THE ENERGY SUPPLIERS
    const [energyCompanyData, setEnergyCompanyData] = useState(null);
    useEffect(() => {
        (async () => {
            const result = await API.graphql(
                graphqlOperation(
                    queryEnergySuppliers, 
                    {
                        defaults_only: true,
                    },
                )
            );   
            setEnergyCompanyData(result.data.queryEnergySuppliers.energy_suppliers)            
        })();
    }, []);

    //THE INITIAL VALUE FOR THE ENERGY SUPPLIER AND FUNCTION TO CHANGE ITS VALUE
    const handleSelect = (eve) => {
        setCompanyNumber(eve.target.value);
    }

    //CREATE ENERGY SUPPLIER OPTIONS
    function energySuppliers(theData){
        var allOptionsArray = []
        if(theData !== null){
            for(var i = 0; i < theData.length; i++){
                allOptionsArray.push
                (
                    <option  key={theData[i].display_name} value={theData[i].company_number}>
                        {theData[i].display_name}
                    </option>
                )
            }
        }
        return allOptionsArray
    }

    //GET USERS CURRENT TARIFF
    const [currentTariff, setCurrentTariff] = useState(null);
    useEffect(() => {
        (async () => {
            const result = await API.graphql(
                graphqlOperation(
                    queryEnergyProducts
                )
            );   
            setCurrentTariff(result.data.queryEnergyProducts.energy_products[0])            
        })();
    }, []);

    //SAVE THE TARIFF TO THE DB
    const saveTariffToDB = async () => {

        var writable = {}

        //THE WRITABLES WHEN THE TARIFF IS SINGLE
        if(energyPriceTypeConstant === true){
            if(currentTariff || currentTariff?.length > 0){
                // THE CASE WE WANT TO UPDATE A TARIFF
                writable = {
                    energy_product: {
                        tariffs: {
                            rate_type: 'standard', 
                            unit_rate: price,
                        }, 
                        standing_charge: standingPrice, 
                        is_fixed: true, 
                        is_green: true,
                        energy_product_name: "Customers Energy Product", 
                        energy_supplier: {company_number: companyNumber},
                        currency: "GBP",
                        contract: {start_date: "2018-01-01"},
                        product_id: currentTariff.product_id,
                        }
                }
            }

            //THE CASE WE WANT TO CREATE A NEW TARIFF
            if(!currentTariff || currentTariff.length === 0){
                writable = {
                    energy_product: {
                        tariffs: {
                            rate_type: 'standard', 
                            unit_rate: price,
                        }, 
                        standing_charge: standingPrice, 
                        is_fixed: true, 
                        is_green: true,
                        energy_product_name: "Customers Energy Product", 
                        energy_supplier: {company_number: companyNumber},
                        currency: "GBP",
                        contract: {start_date: "2018-01-01"}
                        }
                }
            }
        }

        //THE WRITABLES WHEN THE TARIFF IS DUAL
        if(energyPriceTypeVariable === true){
            const peakStartingHour = startTime.getHours() + ':00:00'
            const peakEndingHour = endTime.getHours() + ':00:00'

            if(currentTariff || currentTariff?.length > 0){
                // THE CASE WE WANT TO UPDATE A TARIFF
                writable = {
                    energy_product: {
                        tariffs: [
                            {
                                rate_type: 'day', 
                                unit_rate: peakPrice, 
                                apply_between: {
                                    time_from: peakStartingHour, 
                                    time_to: peakEndingHour
                                }, 
                                apply_from: "2000-01-01"
                            },

                            {
                                rate_type: 'night', 
                                unit_rate: offPeakPrice, 
                                apply_between: {
                                    time_from: peakEndingHour, 
                                    time_to: peakStartingHour
                                }, 
                                apply_from: "2000-01-01"
                            }
                        ], 
                        standing_charge: standingPrice, 
                        is_fixed: false, 
                        is_green: true,
                        energy_product_name: "", 
                        energy_supplier: {company_number: companyNumber},
                        currency: "GBP",
                        contract: {start_date: "2018-01-01"},
                        product_id: currentTariff.product_id
                        }
                }
            }
            //THE CASE WE WANT TO CREATE A NEW TARIFF
            if(!currentTariff || currentTariff.length === 0){
                writable = {
                    energy_product: {
                        tariffs: [
                            {
                                rate_type: 'day', 
                                unit_rate: peakPrice, 
                                apply_between: {
                                    time_from: peakStartingHour, 
                                    time_to: peakEndingHour
                                }, 
                                apply_from: "2000-01-01"
                            },

                            {
                                rate_type: 'night', 
                                unit_rate: offPeakPrice, 
                                apply_between: {
                                    time_from: peakEndingHour, 
                                    time_to: peakStartingHour
                                }, 
                                apply_from: "2000-01-01"
                            }
                        ], 
                        standing_charge: standingPrice, 
                        is_fixed: false, 
                        is_green: true,
                        energy_product_name: "", 
                        energy_supplier: {company_number: companyNumber},
                        currency: "GBP",
                        contract: {start_date: "2018-01-01"}
                        }
                }
            }
        }

        //THE CASE THAT NO TARIFF EXIST FOR THE USER - WE CREATE A NEW TARIFF
        if(!currentTariff || currentTariff.length === 0){
            const addPlug = await API.graphql({
                query: addEnergyProduct, 
                variables: writable
            })
            window.location.reload();
            return
        }

        //THE CASE THAT A TARIFF EXIST FOR THE USER - WE UPDATE THE TARIFF
        else{
            const addPlug = await API.graphql({
                query: updateEnergyProduct, 
                variables: writable
            })
            window.location.reload();
            return
        }
    }

    //CUSTOM INPUT BUTTON
    const DatePickerCustomInput = forwardRef(({ value, onClick }, ref) => (
        <button className={Styles.datePickerButton} onClick={onClick} ref={ref}>
            {value}
        </button>
    ));

    //CHECK THE VALUES AND STORE IN DB
    const checkValues = () => {
        //IN CASE OF SINGLE TARIFF
        if(energyPriceTypeConstant === true){

            if(companyNumber === '0' ){
                setError('Please Select a Company.')
                return
            }

            if(price === 0 ){
                setError('Please Set Your Price.')
                return
            }
            if(price >= 1 ){
                setError('The Price Seems Too High.')
                return
            }

            if(standingPrice === 0 ){
                setError('Please Set Your Standing Price.')
                return
            }
            if(standingPrice >= 1 ){
                setError('The Standing Price Seems Too High.')
                return
            }

            saveTariffToDB()
        }

        //IN CASE OF DUAL TARIFF
        if(energyPriceTypeVariable === true){

            if(companyNumber === '0' ){
                setError('Please Select a Company.')
                return
            }

            if(standingPrice === 0 ){
                setError('Please Set Your Standing Price.')
                return
            }
            if(standingPrice >= 1 ){
                setError('The Standing Price Seems Too High.')
                return
            }

            if(peakPrice === 0 ){
                setError('Please Set Your On Peak Price.')
                return
            }
            if(peakPrice >= 1 ){
                setError('The On Peak Price Seems Too High.')
                return
            }

            if(offPeakPrice === 0 ){
                setError('Please Set Your Off Peak Price.')
                return
            }
            if(offPeakPrice >= 1 ){
                setError('The Off Peak Price Seems Too High.')
                return
            }

            if(endTime < startTime){
                setError('Please Check Your On Peak Starting And Stopping Hours.')
                return
            }

            if(endTime === startTime){
                setError('Please Check Your On Peak Starting And Stopping Hours.')
                return
            }

            saveTariffToDB()
        }
    }
    
    return (
        currentTariff !== null ? 
        <div style={{alignContent:'center', scrollBehavior:'smooth', width:'100%'}} >
            <PageTitle title='Tariffs Settings'/>
            {editMode === false &&
            <div className={Styles.currentTariffBox}>
                <p className={Styles.usageTitle}>
                    Current Tariff
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Energy Supplier
                    </p>
                    <p className={Styles.infoText}>
                        {currentTariff? currentTariff.supplier_name : ''}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Tariff Type                   
                    </p>
                    <p className={Styles.infoText}>
                        {currentTariff? currentTariff.is_fixed === true ? 'Single - Fixed' : 'Dual - Peak/Off Peak' : ''}
                    </p>
                </div>

                <div className={Styles.seperator} />

                <p className={Styles.usageTitle}>
                    Cost
                </p>

                <div className={Styles.seperator} />

                { currentTariff && currentTariff.is_fixed === true ?
                <>
                    <div className={Styles.infoBox2}>
                        <p className={Styles.titleText}>
                            Price Per KWH
                        </p>
                        <p className={Styles.infoText}>
                            £ {currentTariff? currentTariff.tariffs[0].unit_rate : ''}
                        </p>
                    </div>

                    <div className={Styles.infoBox2}>
                        <p className={Styles.titleText}>
                            Standing Charge                   
                        </p>
                        <p className={Styles.infoText}>
                            £ {currentTariff? currentTariff.standing_charges[0].unit_rate : ''}
                        </p>
                    </div>
                </>
                :
                <>

                    <div className={Styles.infoBox3}>
                        <p className={Styles.titleText}>
                            Standing Charge                        
                        </p>
                        <p className={Styles.infoText}>
                            £ {currentTariff? currentTariff.standing_charges[0].unit_rate : ''}
                        </p>
                    </div>

                    <div className={Styles.infoBox3}>
                        <p className={Styles.titleText}>
                            Off Peak Price
                        </p>
                        <p className={Styles.infoText}>
                            £ {currentTariff? currentTariff.tariffs[1].unit_rate : ''}
                        </p>
                    </div>

                    <div className={Styles.infoBox3}>
                        <p className={Styles.titleText}>
                            On Peak Price
                        </p>
                        <p className={Styles.infoText}>
                            £ {currentTariff? currentTariff.tariffs[0].unit_rate : ''}
                        </p>
                    </div>
                </>
                }
                
                { currentTariff && currentTariff.is_fixed === false ?
                <>
                <div className={Styles.seperator} />

                <p className={Styles.usageTitle}>
                    Schedule
                </p>

                <div className={Styles.seperator} />

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Peak Start Time
                    </p>
                    <p className={Styles.infoText}>
                        {currentTariff? currentTariff.tariffs[1].time_to : ''}
                    </p>
                </div>

                <div className={Styles.infoBox2}>
                    <p className={Styles.titleText}>
                        Peak Stop Time              
                    </p>
                    <p className={Styles.infoText}>
                        {currentTariff? currentTariff.tariffs[1].time_from : ''}
                    </p>
                </div>

                </>
                :
                <></>
                }
                    <div className={Styles.seperator} />

                    <button onClick={()=>openEdit()} className={Styles.editButton}>
                        Edit
                    </button>
            </div>
            }
            {/* -------------- EDIT MODE -------------- */}
            {editMode === true ? 
            <div className={Styles.currentTariffBox}>
                <p  style={{fontSize:20, fontWeight:'600', }} ref={editRef} >
                    Please provide the information about your energy supplier 
                </p>

                <div className={Styles.seperator} />

                {error !== '' ? 
                    <>
                        <p style={{ fontSize:24, fontWeight:'600', color:'#FF5E15'}}>
                            {error}
                        </p>

                        <div className={Styles.seperator} />
                    </>
                :
                    <></>
                }

                <div className={Styles.box} >
                    <div className={Styles.labelTarrif} >
                        <label>
                            Energy Supplier
                        </label>
                        <select onChange={handleSelect} >
                            <option  key={'Select'} value={'0'}>Select</option> 
                            {energySuppliers(energyCompanyData)}
                        </select>
                    </div>
                </div>

                <div className={Styles.seperator} />

                <div className={Styles.box} >
                    <div className={Styles.labelTarrif}>
                        <label>
                            Tariff Type
                        </label>
                        
                        <input 
                            className={Styles.radioButton} 
                            type="radio" 
                            name="EnergySupplierPriceConstant" 
                            value={energyPriceTypeConstant} 
                            checked={energyPriceTypeConstant} 
                            onChange={() => [setEnergyPriceTypeConstant(true), setEnergyPriceTypeVariable(false)]} 
                        /> 
                        SINGLE - FIXED

                        <input 
                            className={Styles.radioButton} 
                            type="radio" 
                            name="EnergySupplierPriceVariable" 
                            value={energyPriceTypeVariable} 
                            checked={energyPriceTypeVariable} 
                            onChange={() => [setEnergyPriceTypeVariable(true), setEnergyPriceTypeConstant(false)]} 
                        /> 
                        DUAL - PEAK/OFF PEAK
                    </div>
                </div>

            <div className={Styles.seperator} />

            {energyPriceTypeConstant === true ? 
                <>
                    <div className={Styles.labelTarrif}>
                        <label>
                            Price Per kWh (£)
                        </label>
                        <input type="number" min="0" className={Styles.inputTarrif} value={price} onChange={(e) => setPrice(e.target.value)} name="fname"/>
                    </div>

                    <div className={Styles.labelTarrif}>
                        <label>
                            Standing Charge (£)
                        </label>
                        <input type="number" min="0" className={Styles.inputTarrif} value={standingPrice} onChange={(e) => setStandingPrice(e.target.value)} name="fname"/>
                    </div>
                    <div className={Styles.seperator} />
                </>
            :

            energyPriceTypeVariable === true ? 
                <div className={Styles.box}>

                    <div className={Styles.labelTarrif}>
                        <label>
                            Standing Charge (£)
                        </label>
                        <input type="number" min="0" className={Styles.inputTarrif} value={standingPrice} onChange={(e) => setStandingPrice(e.target.value)} name="fname"/>
                    </div>

                    <div className={Styles.labelTarrif} style={{marginTop:10}}>
                        <label>
                            Off Peak Price Per kWh (£)
                        </label>
                        <input type="number" min="0" className={Styles.inputTarrif} value={offPeakPrice} onChange={(e) => setOffPeakPrice(e.target.value)} name="fname"/>
                    </div>

                    <div className={Styles.labelTarrif} style={{marginTop:10}}>
                        <label>
                            On Peak Price Per kWh (£)
                        </label>
                        <input type="number" min="0" className={Styles.inputTarrif} value={peakPrice} onChange={(e) => setPeakPrice(e.target.value)} name="fname"/>
                    </div>

                    <div className={Styles.seperator} />

                    {/* <p>
                        Please Select The On Peak Starting and Finishing Hours
                    </p> */}

                    <div className={Styles.labelTarrif}>
                        <label style={{position:'absolute', marginTop:7}}>
                            Peak Start Time
                        </label>                    
                        
                        <DatePicker
                            selected={startTime}
                            onChange={(date) => setStartTime(date)}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={60}
                            timeCaption="Time"
                            dateFormat="h:mm aa"

                            calendarClassName={Styles.datePicker}
                            popperClassName={Styles.datePickerPopper}
                            showPopperArrow={false}

                            customInput={<DatePickerCustomInput/>}
                        />
                        
                    </div>

                    <div className={Styles.labelTarrif}>
                        <label style={{position:'absolute', marginTop:7}}>
                            Peak Stop Time
                        </label>                    
                        
                        <DatePicker
                            selected={endTime}
                            onChange={(date) => setEndTime(date)}
                            
                            showTimeSelect
                            showTimeSelectOnly

                            timeIntervals={60}
                            timeCaption="Time"
                            
                            dateFormat="h:mm aa"

                            calendarClassName={Styles.datePicker}
                            popperClassName={Styles.datePickerPopper}
                            showPopperArrow={false}

                            customInput={<DatePickerCustomInput/>}
                        />
                    </div>
                    <div className={Styles.seperator} />
                </div>
            :
            <></>
            }
            <button className={Styles.ButtonCancel} onClick={()=>setEditMode(false)}>
                Cancel
            </button>

            <button className={Styles.ButtonSave} onClick={()=>checkValues()}>
                Save
            </button>
            </div>
            :
            <></>
            }
            
        </div>
        :
        <Loading />
    );
}

