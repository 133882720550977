import React, { useState } from "react";
import { useHistory } from "react-router-dom"
import Styles from './styles/navigationButtons.module.scss';

export default function Detail(props) {

    const [serial, setSerial] = useState(props.serial);

    //Function for navigating to different page
    const navigateTo = (location) => {
        window.location = location
    }

    const LiveClicked = (serial) => {
        history.push(`/live/${serial}`);
    }

    const InfoClicked = (serial) => {
        history.push(`/info/${serial}`);
    }

    const HistoryClicked = (serial) => {
        history.push(`/history/${serial}`);
    }

    const TimelineClicked = (serial) => {
        history.push(`/timeline/${serial}`);
    }

    const energyEfficiencyClicked = (serial) => {
        history.push(`/energy-efficiency/${serial}`);
    }

    const faultDetectionClicked = (serial) => {
        history.push(`/fault-detection/${serial}`);
    }

    let history = useHistory();

    const DeviceCompareClicked = (serial) => {
        history.push(`/device-compare/${serial}`);
    }

    return (
        <div className={Styles.navContainer}>
            <button className={Styles.goBackButton} onClick={()=>navigateTo('/')}>
                Home
            </button>

            {props.currentPage !== 'LivePower' ? 
                <button className={Styles.goBackButton} onClick={()=>LiveClicked(serial)}>
                    Live Data
                </button> 
            :
                <button className={Styles.goBackButtonSelected} onClick={()=>LiveClicked(serial)}>
                    Live Data
                </button> 
            }

            <button className={Styles.goBackButton} onClick={() => window.open("https://visualise.ci.verv.energy/")}>
                High Speed Data
            </button> 

            {props.currentPage !== 'Timeline' ? 
                <button className={Styles.goBackButton} onClick={()=>TimelineClicked(serial)}>
                    Timeline
                </button>
                :
                <button className={Styles.goBackButtonSelected} onClick={()=>TimelineClicked(serial)}>
                    Timeline
                </button>
            }

            {/* <button className={Styles.goBackButton}>
                Fault Detection
            </button>  */}

            {props.currentPage !== 'History' ? 
                <button className={Styles.goBackButton} onClick={()=>HistoryClicked(serial)}>
                    History
                </button>
                :
                <button className={Styles.goBackButtonSelected} onClick={()=>HistoryClicked(serial)}>
                    History
                </button>
            }

            {props.currentPage !== 'CompareDevices' ? 
                <button className={Styles.goBackButton} onClick={()=>DeviceCompareClicked(serial)}>
                    Compare Devices
                </button>
            :
                <button className={Styles.goBackButtonSelected} onClick={()=>DeviceCompareClicked(serial)}>
                    Compare Devices
                </button>
            }

            {/* <button className={Styles.goBackButton} onClick={() => window.open("https://ers.connect.verv.energy/")}>
                Energy Report
            </button> */}

            {props.currentPage !== 'EnergyEfficiency' ? 
                <button className={Styles.goBackButton} onClick={()=>energyEfficiencyClicked(serial)}>
                    Energy Efficiency
                </button>
            :
                <button className={Styles.goBackButtonSelected} onClick={()=>energyEfficiencyClicked(serial)}>
                    Energy Efficiency
                </button>
            }

            {/* {props.currentPage !== 'FaultDetection' ? 
                <button className={Styles.goBackButton} onClick={()=>faultDetectionClicked(serial)}>
                    Fault Detection
                </button>
            :
                <button className={Styles.goBackButtonSelected} onClick={()=>faultDetectionClicked(serial)}>
                    Fault Detection
                </button>
            } */}

            {props.currentPage !== 'MoreInfo' ? 
                <button className={Styles.goBackButton} onClick={()=>InfoClicked(serial)}>
                    More Info
                </button>
            :
                <button className={Styles.goBackButtonSelected} onClick={()=>InfoClicked(serial)}>
                    More Info
                </button>
            }

            <div className={Styles.seperator} />

        </div>
    );
}
