import React, { useEffect, useState } from "react";
import Styles from './styles/trafficlight.module.scss';
import { API, graphqlOperation } from 'aws-amplify';
import { queryVervConnects } from '../graphql/queries';

import dayjs from "dayjs";
import calendar from 'dayjs/plugin/calendar';

dayjs.extend(calendar);


function MomentDate ({value}) {
    const isValid = dayjs(value).isValid()
    return (
        <span style={{fontSize:12}}>{isValid ? dayjs(value).calendar() : '–'}</span>
    )
}


const LastS3Item = ({Props}) => {

    const [items, setItem] = useState([]);

    useEffect(() => { 
        API.graphql(
            graphqlOperation(
                queryVervConnects, { 
                    serial_number: Props.cell.value,
                    last_s3_item: true,
                }
            )
        ).then(result => setItem(result.data.queryVervConnects.items)
        )},
    []);

    if (items && items.length) {

        if (Props.cell.column.id === "S3-24-hours") {
            return (
                <span className={`${Styles.light} ${items[0].s3_within_24h === true ? '':Styles.off}`}></span>
            )
        } else if (Props.cell.column.id === "S3-1-week") {
            return (
                <span className={`${Styles.light} ${items[0].s3_within_1w === true ? '':Styles.off}`}></span>
            )
        } else if (Props.cell.column.id === "S3-28-days") {
            return (
                <span className={`${Styles.light} ${items[0].s3_within_28 === true ? '':Styles.off}`}></span>
            )
        } else {
            return (
                <p> <MomentDate value={items[0].last_s3_item}/> </p>
            )
        }
 
    } else {
        return (
            <p>waiting...</p>
        )
    }
} 

export default LastS3Item;
