// Table.js
import React, { useState } from "react";
import { useFilters, useSortBy, useTable, useExpanded } from "react-table";
import styles from './styles/table.module.scss';
import { motion } from 'framer-motion';

export default function Table({ columns, data, withSearch }) {
    // Create a state
    const [filterInput, setFilterInput] = useState("");
    const [filterSerial, setFilterSerial] = useState("");

    // Use the useTable Hook to send the columns and data to build the table
    const {
        getTableProps, // table props from react-table
        getTableBodyProps, // table body props from react-table
        headerGroups, // headerGroups, if your table has groupings
        rows, // rows for the table based on the data passed
        prepareRow,
        setFilter // Prepare the row (this function needs to be called for each row before getting the row props)
    } = useTable({columns,data, initialState:{hiddenColumns:[]}},useFilters, useSortBy, useExpanded);

    // Update the state when input changes
    const handleFilterChange = e => {
        const value = e.target.value || undefined;
        setFilter("location", value); // Update the serial filter. Now our table will filter and show only the rows which have a matching value
        setFilterInput(value);
    };

    // Update the state when input changes
    const handleSerialChange = e => {
        const value = e.target.value || undefined;
        setFilter("serial", value); // Update the serial filter. Now our table will filter and show only the rows which have a matching value
        setFilterSerial(value);
    };

/* 
Render the UI for your table
- react-table doesn't have UI, it's headless. We just need to put the react-table props from the Hooks, and it will do its magic automatically
*/
return (
    <>
    {withSearch ?
    <>
        <div className={styles.meta}>
            <motion.label animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}}>
                Search Location
                <input
                    className={styles.input}
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Device Location..."}
                />
            </motion.label>
        </div>
    
        <div className={styles.meta}>
            <motion.label animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}}>
                Search Serial
                <input
                    className={styles.input}
                    value={filterSerial}
                    onChange={handleSerialChange}
                    placeholder={"Plug Serial..."}
                />
            </motion.label>
        </div>
    </>
    : null }


    <motion.table 
        animate={{ y:data.length > 0 ? 0 : 60, opacity: data.length > 0 ? 1 : 0 }}  transition={{ duration: 1 }} initial={{opacity:0}} className={styles.table} style={{cursor: 'auto'}} {...getTableProps()}>
        
        <thead>
            {headerGroups.map(headerGroup => (

                <tr {...headerGroup.getHeaderGroupProps()}>

                    {headerGroup.headers.map(column => (

                        <th {...column.getHeaderProps(column.getSortByToggleProps())}>

                            <span style={{verticalAlign: 'middle'}}>{column.render("Header")}</span>
                            <br/>
                            {column.isSorted ? 
                            <span style={{width:'15px',height:'15px',display:'inline-block',verticalAlign: 'middle'}}>
                                {column.isSorted
                                ? column.isSortedDesc
                                    ? '↓'
                                    : '↑'
                                : ''}
                            </span>
                            :<></>
                            }

                        </th>

                    ))}

                </tr>

            ))}
        </thead>

        <tbody {...getTableBodyProps()}>

            {rows.map((row, i) => {
                prepareRow(row);
                const rowProps = row.getRowProps();

                return (
                    <React.Fragment key={rowProps.key}>
                        <tr {...row.getToggleRowExpandedProps()}>
                            {row.cells.map(cell => {
                                return <td {...cell.getCellProps()}>{cell.render("Cell")}</td> ;
                                
                            })}
                        </tr>
                    </React.Fragment>
                );
            })}

        </tbody>
    </motion.table>

    </>
    );
}
