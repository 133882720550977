import React, { useEffect, useState, } from 'react';
import Styles from './styles/emailNotification.module.scss';

import {updateNotificationConfig, deleteNotificationConfig, createNotificationConfig} from '../graphql/mutations';
import {queryVervConnects} from '../graphql/queries';

import { API } from 'aws-amplify';

import Switch from "react-switch";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
const animatedComponents = makeAnimated();

const EmaiLNotification = ({email, plugs, notification1, notification2, id, addNew, user_sub, setNewPlugAdded, newPlugAdded }) =>  {
    //DISPLAY ALL THE PLUGS
    const plugNames = plugs.map((text) =>
        <p style={{marginRight:5}}>{text + ' | '}</p>
    );

    //EDIT MODE OPEN OR CLOSE
    const [editMode, setEditMode] = useState(false);

    const [error, setError] = useState('');

    //ADD NEW EMAIL MODE 
    const [addNewMode, setAddNewMode] = useState(addNew);

    //INPUT DATA CHECK ---------------------------------------------------------------------
    function validateEmail(email) {
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(email);
    }

    //ADD A NEW NOTIFICATION 
    const saveNewNotification = async () => {
        //CHECK ALL THE INPUTS
        if (!validateEmail(emailInput)) {
            setError("The email is in a wrong format.");
            return;
        }

        if(notificationType1 === false && notificationType2 === false){
            setError("Please select a notification type.");
            return;
        }

        if(selectedPlug.length === 0){
            setError("Please select a device.");
            return;
        }

        //SET ALL THE IDs ---
        var allPlugsIDs = []
        selectedPlug.forEach(element => allPlugsIDs.push(element.value))

        //SET ALL THE NOTIF TYPES ---
        var notificationType = []
        notificationType1 === true ? notificationType.push('ENERGY_EFFICIENCY') : notificationType = notificationType
        notificationType2 === true ? notificationType.push('FAULT_DETECTION') : notificationType = notificationType

        var writable = {
            notification_config: {
                id: 0,
                type: 0,
                address: emailInput,
                serials_list: allPlugsIDs,
                checks_list: notificationType
            }
        }

        const addNotif = await API.graphql({
            query: createNotificationConfig, 
            variables: writable
        })

        setNotifID('')
        setNotificationType1(false)
        setNotificationType2(false)
        setEmailInput('')
        setSelectedPlugs('')
        setError('')

        setNewPlugAdded(newPlugAdded+1)

        editModeToggleCancel()
        window.location.reload();

        return
    }

    //UPDATE A NOTIFICATION
    const updateNotification = async () => {

        //CHECK ALL THE INPUTS
        if (!validateEmail(emailInput)) {
            setError("The email is in a wrong format.");
            return;
        }

        if(notificationType1 === false && notificationType2 === false){
            setError("Please select a notification type.");
            return;
        }

        if(selectedPlug.length === 0){
            setError("Please select a device.");
            return;
        }

        //SET ALL THE IDs ---
        var allPlugsIDs = []
        selectedPlug.forEach(element => allPlugsIDs.push(element.value))

        //SET ALL THE NOTIF TYPES ---
        var notificationType = []
        notificationType1 === true ? notificationType.push('ENERGY_EFFICIENCY') : notificationType = notificationType
        notificationType2 === true ? notificationType.push('FAULT_DETECTION') : notificationType = notificationType

        console.log(allPlugsIDs)

        var writable = {
            notification_config: {
                id: id,
                type: 0,
                address: emailInput,
                serials_list: allPlugsIDs,
                checks_list: notificationType
            }
        }

        const updateNotif = await API.graphql({
            query: updateNotificationConfig, 
            variables: writable
        })

        setError('')

        editModeToggleCancel()
        window.location.reload();

        return
    }
    
    //DELETE A NOTIFICATION
    const [makeSureDelete, setMakeSureDelete] = useState(false);

    const deleteNotification = () => {
        var writable = {
            notification_id: notifId
        }
        
        const removeNotif =  API.graphql({
            query: deleteNotificationConfig, 
            variables: writable
        }).then(setDeleted(true))
        return
    }

    const saveOrUpdateNewNotification = () => {
        if (addNew === true){
            saveNewNotification()
            return
        }
        if (addNew === false){
            updateNotification()
            return
        }    
    }
    
    const editModeToggle = () =>{
        setAddNewMode(false)
        setEditMode(true)
    }
    const editModeToggleCancel = () =>{
        setAddNewMode(true)
        setEditMode(false)
    }
    //EDIT MODE OPEN OR CLOSE
    const [deleted, setDeleted] = useState(false);

    //NOTIFICATION INFORMATIONS
    const [emailInput, setEmailInput] = useState(email);
    const [notificationType1, setNotificationType1] = useState(notification1);
    const [notificationType2, setNotificationType2] = useState(notification2);
    const [notifId, setNotifID] = useState(id);
    const [selectedPlug, setSelectedPlugs] = useState(plugs);


    //DISPLAY ALL THE PLUGS THAT THE CURRENT USER HAS
    const [data, setData] = useState(null);
    // Using useEffect to call the API once mounted and set the data
    useEffect(() => {
        (async () => {
            const result = await API.graphql({ query: queryVervConnects });
            setData(result.data.queryVervConnects.items);
            var tempArray = []
            for(var i = 0; i < result.data.queryVervConnects.items?.length; i++ ){
                let plugInfo = {
                    value: result.data.queryVervConnects.items[i].serial,
                    label: result.data.queryVervConnects.items[i].appliance.nickname + ' (' + result.data.queryVervConnects.items[i].serial + ')'
                }
                tempArray.push(plugInfo)
            }    
            setData(tempArray)
        })();
    }, []);

    //CUSTOM STYLE FOR THE PLUG PICKER
    const customStyles = {
        option: (provided, state) => ({
        ...provided,
        borderRadius:10,
        }),

        singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = 'opacity 300ms';
    
        return { ...provided, opacity, transition };
        }
    }
    
    return (
        deleted === false ? 
            <div className={Styles.mainWrapper}>
                {makeSureDelete === false ?
                    addNewMode === false ? 
                    <>
                    {
                    // ---------- EMAIL AND EDIT BUTTON ----------
                    }
                    <div className={Styles.emailEditSection}>
                        <p className={Styles.emailText}>{emailInput}</p>
                        {editMode === false ? 
                        <button className={Styles.editButton} onClick={()=>setEditMode(true)}>Edit</button>
                        :
                        addNew === false ? 
                            <button className={Styles.ButtonDelete} onClick={()=>[setMakeSureDelete(true)]}>Delete</button>
                        :
                        <></>
                        }
                    </div>

                    {
                    // ---------- DISPLAY ALL THE PLUGS ----------
                    }
                    <div className={Styles.emailEditSection}>
                        <p>Devices: </p>
                        {plugNames}
                    </div>

                    {
                    // ---------- EDIT NOTIFICATION ----------
                    }
                    {
                        editMode === false ? 
                        <></>
                        :
                        <>
                        <div className={Styles.seperator} />
                        <div className={Styles.emailEditSection}>
                            <label className={Styles.labelTarrif} style={{marginTop:10, marginBottom:10}}>
                                <p>Email: </p>
                                <input type="email" className={Styles.input} value={emailInput} onChange={(e) => setEmailInput(e.target.value)} name="fname"/>
                            </label>
                        </div>

                        <div className={Styles.seperator} />

                        <div className={Styles.emailEditSection}>
                            <div className={Styles.switch}>
                                <p style={{fontSize:17}}>
                                    Energy usage efficiency 
                                </p>

                                <div className={Styles.switchdiv}>
                                    <Switch
                                        onChange={()=>setNotificationType1(!notificationType1)}
                                        checked={notificationType1}
                                        className="react-switch"

                                        onColor="#2BBCB9"
                                        
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className={Styles.emailEditSection}>
                            <div className={Styles.switch}>
                                <p style={{fontSize:17}}>
                                    Fault detection
                                </p>

                                <div className={Styles.switchdiv}>
                                    <Switch
                                        onChange={()=>setNotificationType2(!notificationType2)}
                                        checked={notificationType2}
                                        className="react-switch"

                                        onColor="#2BBCB9"
                                        
                                        uncheckedIcon={false}
                                        checkedIcon={false}
                                    />
                                </div>
                            </div>
                        </div>

                        <div className={Styles.seperator} />

                        <Select
                            closeMenuOnSelect={false}
                            components={animatedComponents}
                            isMulti
                            options={data}

                            onChange={setSelectedPlugs}

                            styles={customStyles}

                            theme={(theme) => ({
                                ...theme,
                                borderRadius: 15,
                                colors: {
                                    ...theme.colors,
                                    primary25: '#BAEAE7',
                                    primary50:'#B9EAE8',
                                    primary: '#34485A',
                                    danger:'#000',
                                    dangerLight:'#FF5E15'

                                    },
                            })}
                        />

                        {error != '' ? 
                            <>
                                <div className={Styles.seperator} />

                                <p style={{ fontSize:24, fontWeight:'600', color:'#FF5E15'}}>
                                    {error}
                                </p>

                            </>
                        :
                            <></>
                        }

                        <div className={Styles.seperator} />

                        <div>
                            <button className={Styles.ButtonCancel} onClick={addNew === false ? () => setEditMode(false) : () => editModeToggleCancel() } >
                                Cancel
                            </button>
                            <button className={Styles.ButtonSave} onClick={()=>[saveOrUpdateNewNotification(), editModeToggle()]} >
                                Save
                            </button>
                        </div>

                        </>
                    }
                    </>
                    : 
                    <div>
                        <button className={Styles.ButtonAddNew} onClick={()=>[editModeToggle()]} >
                            Add New
                        </button>
                    </div>
                :
                <div>
                    <p style={{fontSize:20, fontWeight:600, marginLeft:25}}>
                        Are you sure you want to delete? 
                    </p>
                    <div style={{marginTop:10}}>
                        <button className={Styles.ButtonSave} onClick={() => setMakeSureDelete(false)} >
                            Cancel
                        </button>
                        <button className={Styles.ButtonCancel} onClick={()=>deleteNotification()} >
                            Confirm Delete
                        </button>
                    </div>                
                </div>
                }
            </div>
        :
        <></>
        
        
    );
}

export default EmaiLNotification;

