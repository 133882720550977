import Styles from './styles/loading.module.scss';

function ApplianceNotTurnedOnMessage(is_On) {
    return (
        <>
        <div className={Styles.loader}>
            <span></span>
        </div>      

        <p style={{fontSize:35, fontWeight:'600', marginTop:40, textAlign:'center'}}>
            We are looking for your data <br/> Please ensure that your appliance is turned on.
        </p>
        </>
    )
}

export default ApplianceNotTurnedOnMessage
